import isNil from 'lodash.isnil';

export const formatNumber = (number) => {
  return Intl.NumberFormat().format(number);
};

export const toCurrency = (
  number,
  style = 'currency',
  minimumFractionDigits = 2,
  locale = 'de-CH',
  currency = 'CHF',
  maximumFractionDigits = 2
) => {
  return new Intl.NumberFormat(locale, {
    style,
    currency,
    minimumFractionDigits,
    maximumFractionDigits
  }).format(number);
};

const discountLocaleTemplates = {
  en(discount) {
    return `${discount}% Off`;
  },
  default(discount) {
    return `-${discount}%`;
  }
};
export const formatDiscount = (discount, locale = 'fr') => {
  if (typeof discountLocaleTemplates[locale] === 'function') {
    return discountLocaleTemplates[locale](discount);
  }
  return discountLocaleTemplates.default(discount);
};

export const applyTax = (amount, percentage = 0) => {
  return amount * (1 + percentage / 100);
};

export const removeTax = (amount, percentage = 0) => {
  return amount / (1 + percentage / 100);
};

export const toNumber = (currency) => {
  if (isNil(currency)) return null;
  return parseFloat(
    currency.replace('CHF', '').replace(',', '.').replace(' ', '')
  );
};
