import { defineStore } from 'pinia';
import { getMercureData } from '@/api/mercure';
import isNil from 'lodash.isnil';
import isEmpty from 'lodash.isempty';
import jwt_decode from 'jwt-decode';

export const useMercureStore = defineStore('mercure', {
  state: () => ({
    userId: null,
    hubUrl: null,
    authorization: null,
    topics: [],
    connected: false
  }),
  getters: {
    isConnectable(state) {
      return (
        !isNil(state.hubUrl) &&
        !isNil(state.authorization) &&
        !isEmpty(state.topics)
      );
    },
    connectionData(state) {
      return {
        hubUrl: state.hubUrl,
        authorization: state.authorization,
        topics: state.topics
      };
    }
  },
  actions: {
    async clearMercureData() {
      const { $mercure } = this.$nuxt;
      this.$patch({
        userId: null,
        hubUrl: null,
        authorization: null,
        topics: []
      });
      // Disconnect mercure evnet source
      $mercure.disconnectAndClear();
    },
    async fetchMercureData(userId) {
      const { $api, $mercure } = this.$nuxt;
      let isUpdated = false;
      this.$patch({ userId });
      try {
        const { headers, data } = await $api.doRequest(getMercureData, userId);
        const { topics } = data;

        if (!isNil(headers.link)) {
          // Extract the hub URL from the Link header
          const hubUrl =
            headers.link.match(
              /<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/
            )?.[1] || null;
          if (!isNil(hubUrl) && this.hubUrl !== hubUrl) {
            this.$patch({ hubUrl });
            isUpdated = true;
          }
        }

        if (!isNil(headers['x-mercure-authorization'])) {
          // Extract the hub URL from the Link header
          const authorization = headers['x-mercure-authorization'];
          if (!isNil(authorization) && this.authorization !== authorization) {
            let shouldUpdateAuthorization = isNil(this.authorization);
            if (!isNil(this.authorization)) {
              const { exp } = jwt_decode(this.authorization);
              const expDate = new Date(exp * 1000);
              const now = new Date();
              shouldUpdateAuthorization = now >= expDate;
            }
            if (shouldUpdateAuthorization) {
              this.$patch({ authorization });
              isUpdated = true;
            }
          }
        }

        if (!isEmpty(topics)) {
          const hasTopicNotIncluded = topics.some(
            (topic) => !this.topics.includes(topic)
          );
          if (hasTopicNotIncluded) {
            this.$patch({ topics });
            isUpdated = true;
          }
        }

        if ((isUpdated || !this.connected) && this.isConnectable) {
          $mercure.subscribe(this.connectionData);
        }
      } catch (err) {}
    },
    async setConnectedState(connected) {
      this.$patch({ connected });
    }
  }
});
