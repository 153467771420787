<template>
  <component
    class="category-icon"
    :is="componentName"
    :style="style"
    :v-bind="$attrs"
  />
</template>

<script>
import { pascalCase } from '@/misc/string';

export default {
  name: 'CategoryIcon',
  components: {
    BebeFilleAccessoires: () =>
      import('~/assets/img/category/bebe-fille-accessoires.svg?inline'),
    BebeFilleChaussures: () =>
      import('~/assets/img/category/bebe-fille-chaussures.svg?inline'),
    BebeFilleVetements: () =>
      import('~/assets/img/category/bebe-fille-vetements.svg?inline'),
    BebeGarconAccessoires: () =>
      import('~/assets/img/category/bebe-garcon-accessoires.svg?inline'),
    BebeGarconChaussures: () =>
      import('~/assets/img/category/bebe-garcon-chaussures.svg?inline'),
    BebeGarconVetements: () =>
      import('~/assets/img/category/bebe-garcon-vetements.svg?inline'),
    FemmeAccessoires: () =>
      import('~/assets/img/category/femme-accessoires.svg?inline'),
    FemmeBeaute: () => import('~/assets/img/category/femme-beaute.svg?inline'),
    FemmeChaussures: () =>
      import('~/assets/img/category/femme-chaussures.svg?inline'),
    FemmeMontresBijoux: () =>
      import('~/assets/img/category/femme-montres-bijoux.svg?inline'),
    FemmeSacsMaroquinerie: () =>
      import('~/assets/img/category/femme-sacs-maroquinerie.svg?inline'),
    FemmeVetements: () =>
      import('~/assets/img/category/femme-vetements.svg?inline'),
    FilleChaussures: () =>
      import('~/assets/img/category/fille-chaussures.svg?inline'),
    FilleSacsAccessoires: () =>
      import('~/assets/img/category/fille-sacs-accessoires.svg?inline'),
    FilleVetements: () =>
      import('~/assets/img/category/fille-vetements.svg?inline'),
    GarconChaussures: () =>
      import('~/assets/img/category/garcon-chaussures.svg?inline'),
    GarconSacsAccessoires: () =>
      import('~/assets/img/category/garcon-sacs-accessoires.svg?inline'),
    GarconVetements: () =>
      import('~/assets/img/category/garcon-vetements.svg?inline'),
    HommeAccessoires: () =>
      import('~/assets/img/category/homme-accessoires.svg?inline'),
    HommeBeaute: () => import('~/assets/img/category/homme-beaute.svg?inline'),
    HommeChaussures: () =>
      import('~/assets/img/category/homme-chaussures.svg?inline'),
    HommeMontresBijoux: () =>
      import('~/assets/img/category/homme-montres-bijoux.svg?inline'),
    HommeSacsMaroquinerie: () =>
      import('~/assets/img/category/homme-sacs-maroquinerie.svg?inline'),
    HommeVetements: () =>
      import('~/assets/img/category/homme-vetements.svg?inline')
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    fill: {
      type: String,
      default: null
    }
  },
  computed: {
    componentName() {
      return pascalCase(this.icon);
    },
    style() {
      const style = {};
      if (this.fill) style['fill'] = this.fill;
      return style;
    }
  }
};
</script>

<style lang="scss" scoped>
.category-icon {
  fill: $mine-shaft;

  path {
    fill: inherit;
  }
}
</style>
