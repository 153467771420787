<template>
  <div class="password-reset-request">
    <h2 class="password-reset-request__heading">
      {{ $t('auth.password-reset-request.heading') }}
    </h2>
    <div v-if="error" class="password-reset-request__error">{{ error }}</div>
    <ValidationObserver ref="resetPasswordForm" tag="div" v-slot="{ invalid }">
      <form
        class="password-reset-request__body d-flex flex-column"
        @submit.prevent="onResetPassword"
      >
        <InputRow
          v-model="email"
          type="email"
          :label="$t('auth.password-reset-request.email.label')"
          rules="required|email"
        />
        <div class="d-flex align-items-center justify-content-between">
          <div>
            {{ $t('components.authentication.i-have-an') }}
            <a
              href="javascript:void(0)"
              class="password-reset-request__step-switch"
              @click="$emit('step:switch', 'sign-in')"
            >
              {{ $t('components.authentication.account') }}
            </a>
          </div>
          <Button :disabled="invalid" @click="onResetPassword">
            {{ $t('auth.password-reset-request.cta') }}
          </Button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import InputRow from '@/components/Form/InputRow/InputRow';
import Button from '@/components/Button/Button';
import { captureErrorMixin } from '@/mixins/captureError';
import { passwordResetRequest } from '@/api/auth';

export default {
  name: 'PasswordResetRequest',
  components: { ValidationObserver, InputRow, Button },
  mixins: [captureErrorMixin],
  data: () => ({
    error: null,
    email: ''
  }),
  methods: {
    async onResetPassword() {
      const success = await this.$refs.resetPasswordForm.validate();
      if (!success) return;
      this.$emit('password-reset-request:before');
      await this.try(
        async () => {
          const { success } = await this.$api.doRequest(
            passwordResetRequest,
            this.email
          );
          success && this.$emit('password-reset-request:success');
        },
        (err) => (this.error = err.response?.data?.message || null)
      );
      this.$emit('password-reset-request:after');
    }
  }
};
</script>

<style lang="scss" scoped src="./password-reset-request.scss"></style>
