import Vue from 'vue';

export default (ctx) => {
  Vue.directive('internal-link-detect', {
    bind(el) {
      const {
        app: { router }
      } = ctx;
      const links = el.getElementsByTagName('a');
      for (let index = 0; index < links.length; index++) {
        const link = links[index];
        const href = link.getAttribute('href');
        if (!href) continue;
        const target = link.getAttribute('target');
        if ('_blank' === target) continue;
        const url = new URL(href, document.location.origin);
        if (!['http:', 'https:'].includes(url.protocol)) continue;
        let internal =
          document.location.origin === url.origin ||
          !href.match(/^https?:\/\//)?.length;
        if (internal) {
          link.addEventListener('click', (event) => {
            event.preventDefault();
            router.push(url.pathname);
          });
        }
      }
    }
  });
};
