import { extend } from 'vee-validate';
import {
  email,
  min,
  regex,
  required,
  between,
  length,
  min_value,
  max_value
} from 'vee-validate/dist/rules';
import IBAN from 'iban';
import parsePhoneNumber from 'libphonenumber-js';

const SEPA_CODES = [
  'AD', // Andorre
  'AT', // Autriche
  'BE', // Belgique
  'BG', // Bulgarie
  'CH', // Suisse
  'CY', // Chypre
  'CZ', // République tchèque
  'DE', // Allemagne
  'DK', // Danemark
  'EE', // Estonie
  'ES', // Espagne
  'FI', // Finlande
  'FR', // France
  'GB', // Royaume-Uni
  'GI', // Gibraltar
  'GR', // Grèce
  'HR', // Croatie
  'HU', // Hongrie
  'IE', // Irlande
  'IS', // Islande
  'IT', // Italie
  'LI', // Liechtenstein
  'LT', // Lituanie
  'LU', // Luxembourg
  'LV', // Lettonie
  'MC', // Monaco
  'MT', // Malte
  'NL', // Pays-Bas
  'NO', // Norvège
  'PL', // Pologne
  'PT', // Portugal
  'RO', // Roumanie
  'SE', // Suède
  'SI', // Slovénie
  'SK', // Slovaquie
  'SM' // Saint-Marin
];

export default ({ app }) => {
  extend('required', {
    ...required,
    message: (_, values) => app.i18n.t('validations.required', values)
  });

  extend('email', {
    ...email,
    message: (_, values) => app.i18n.t('validations.format-email', values)
  });

  extend('min', {
    ...min,
    message: (_, values) => app.i18n.t('validations.min', values)
  });

  extend('regex', {
    ...regex,
    message: (_, values) => app.i18n.t('validations.regex', values)
  });

  extend('between', {
    ...between,
    message: (_, values) => app.i18n.t('validations.between', values)
  });

  extend('length', {
    ...length,
    message: (_, values) => app.i18n.t('validations.length', values)
  });

  extend('min_value', {
    ...min_value,
    message: (_, values) => app.i18n.t('validations.min-value', values)
  });

  extend('max_value', {
    ...max_value,
    message: (_, values) => app.i18n.t('validations.max-value', values)
  });

  extend('currency', {
    validate(value, args) {
      let amount = value;
      if (typeof value === 'string')
        amount = value.replace(/[^\d\.]+/, '').replace('’', '');
      return between.validate(amount, args);
    },
    params: ['min', 'max'],
    message: (_, values) => {
      const min = new Intl.NumberFormat('de-CH', {
        style: 'currency',
        currency: 'CHF',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(values.min);
      const max = new Intl.NumberFormat('de-CH', {
        style: 'currency',
        currency: 'CHF',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(values.max);
      return app.i18n.t('validations.currency', { min, max });
    }
  });

  extend('birthday', {
    validate(value) {
      const regex = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d$/;
      return regex.test(value);
    },
    message: (_, values) => app.i18n.t('validations.birthday', values)
  });

  extend('password', {
    validate(value) {
      let strength = 0;
      // Check password length
      if (value.length >= 7) strength++;
      // Check for mixed case
      // if (value.match(/[a-z]/) && value.match(/[A-Z]/)) strength++;
      // Check for numbers
      if (value.match(/\d/)) strength++;
      // Check for special characters
      if (value.match(/[^a-zA-Z\d]/)) strength++;
      return strength >= 3;
      // return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{7,}$/.test(
      //   value
      // );
      // const hasDigit = /\d/.test(value);
      // const hasSpecial = false;
      // return hasDigit && hasSpecial && min.validate(value, { length: 7 });
    },
    message: (_, values) => app.i18n.t('validations.password', values)
  });

  extend('same', {
    validate(value, args) {
      return value === args.original;
    },
    params: ['original'],
    message: (_, values) => {
      return app.i18n.t('validations.confirmed', values);
    }
  });

  extend('iban', {
    validate(value) {
      const iban = value.trim();
      return IBAN.isValid(iban) && SEPA_CODES.includes(iban.slice(0, 2));
    },
    message: (_, values) => app.i18n.t('validations.iban', values)
  });

  extend('phone_number', {
    validate(value) {
      const phoneNumber = parsePhoneNumber(value, 'CH');
      return phoneNumber?.isValid() || false;
    },
    message: (_, values) => app.i18n.t('validations.phone-number', values)
  });

  extend('2fa_auth_code', {
    validate(value) {
      const code = value.replace(/\s/g, '');
      return code.length === 6;
    },
    message: (_, values) => app.i18n.t('validations.2fa-auth-code', values)
  });
};
