<template>
  <div class="empty-layout">
    <div class="body">
      <nuxt />
    </div>
    <Axeptio />
  </div>
</template>

<script>
import Axeptio from '@/components/Axeptio/Axeptio';

export default {
  name: 'EmptyLayout',
  components: { Axeptio }
};
</script>

<style lang="scss" scoped>
.empty-layout {
  display: flex;
  flex-direction: column;

  .body {
    position: relative;
    min-height: 100vh;
  }
}
</style>
