import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_sentryserver_39a43726 from 'nuxt_plugin_sentryserver_39a43726' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_7d7472e5 from 'nuxt_plugin_sentryclient_7d7472e5' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_plugin_1a0bccbb from 'nuxt_plugin_plugin_1a0bccbb' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_templatesplugin84f09a68_3027c4e9 from 'nuxt_plugin_templatesplugin84f09a68_3027c4e9' // Source: ./templates.plugin.84f09a68.js (mode: 'client')
import nuxt_plugin_templatesplugin58e22f80_1472843d from 'nuxt_plugin_templatesplugin58e22f80_1472843d' // Source: ./templates.plugin.58e22f80.js (mode: 'client')
import nuxt_plugin_vuesocialsharingplugin_61aaaa22 from 'nuxt_plugin_vuesocialsharingplugin_61aaaa22' // Source: ./vue-social-sharing-plugin.js (mode: 'all')
import nuxt_plugin_deviceplugin_0553bc34 from 'nuxt_plugin_deviceplugin_0553bc34' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_6a7f0b51 from 'nuxt_plugin_cookieuniversalnuxt_6a7f0b51' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_vuescrollto_7ca9af80 from 'nuxt_plugin_vuescrollto_7ca9af80' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_portalvue_380c304a from 'nuxt_plugin_portalvue_380c304a' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_axios_328844ae from 'nuxt_plugin_axios_328844ae' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_pluginutils_2223109c from 'nuxt_plugin_pluginutils_2223109c' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_b9a03f06 from 'nuxt_plugin_pluginrouting_b9a03f06' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_778fb7dc from 'nuxt_plugin_pluginmain_778fb7dc' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_distplugind9d6da42_2e59e8a5 from 'nuxt_plugin_distplugind9d6da42_2e59e8a5' // Source: ./dist.plugin.d9d6da42.mjs (mode: 'all')
import nuxt_plugin_workbox_ade8e6f6 from 'nuxt_plugin_workbox_ade8e6f6' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_b8b302d0 from 'nuxt_plugin_metaplugin_b8b302d0' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_af63b7e8 from 'nuxt_plugin_iconplugin_af63b7e8' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_promisestack_1f7078c0 from 'nuxt_plugin_promisestack_1f7078c0' // Source: ../plugins/promise-stack (mode: 'all')
import nuxt_plugin_nuxtstore_30e92d35 from 'nuxt_plugin_nuxtstore_30e92d35' // Source: ../plugins/nuxt.store.plugin (mode: 'all')
import nuxt_plugin_global_6441a2ce from 'nuxt_plugin_global_6441a2ce' // Source: ../plugins/global (mode: 'all')
import nuxt_plugin_mediaquery_6c026069 from 'nuxt_plugin_mediaquery_6c026069' // Source: ../plugins/media-query (mode: 'all')
import nuxt_plugin_bus_5e46274a from 'nuxt_plugin_bus_5e46274a' // Source: ../plugins/bus (mode: 'all')
import nuxt_plugin_filters_98405076 from 'nuxt_plugin_filters_98405076' // Source: ../plugins/filters (mode: 'all')
import nuxt_plugin_veevalidate_6e5ad03a from 'nuxt_plugin_veevalidate_6e5ad03a' // Source: ../plugins/vee-validate (mode: 'all')
import nuxt_plugin_vuescroll_0c9cb62c from 'nuxt_plugin_vuescroll_0c9cb62c' // Source: ../plugins/vue-scroll (mode: 'all')
import nuxt_plugin_vuejsmodal_f50827f4 from 'nuxt_plugin_vuejsmodal_f50827f4' // Source: ../plugins/vue-js-modal (mode: 'all')
import nuxt_plugin_vscrolllock_a40204c4 from 'nuxt_plugin_vscrolllock_a40204c4' // Source: ../plugins/v-scroll-lock (mode: 'all')
import nuxt_plugin_vuelazyload_211b2937 from 'nuxt_plugin_vuelazyload_211b2937' // Source: ../plugins/vue-lazyload (mode: 'all')
import nuxt_plugin_vueobservevisibility_2d7eef00 from 'nuxt_plugin_vueobservevisibility_2d7eef00' // Source: ../plugins/vue-observe-visibility (mode: 'all')
import nuxt_plugin_vuetippy_1467d320 from 'nuxt_plugin_vuetippy_1467d320' // Source: ../plugins/vue-tippy.js (mode: 'all')
import nuxt_plugin_svgxuse_0493c29a from 'nuxt_plugin_svgxuse_0493c29a' // Source: ../plugins/svgxuse.js (mode: 'all')
import nuxt_plugin_mercure_1fa522c9 from 'nuxt_plugin_mercure_1fa522c9' // Source: ../plugins/mercure (mode: 'client')
import nuxt_plugin_vuetoastification_bd752598 from 'nuxt_plugin_vuetoastification_bd752598' // Source: ../plugins/vue-toastification (mode: 'client')
import nuxt_plugin_piniapluginpersist_06683d2e from 'nuxt_plugin_piniapluginpersist_06683d2e' // Source: ../plugins/pinia-plugin-persist.client (mode: 'client')
import nuxt_plugin_internallinkdetect_6fa57e86 from 'nuxt_plugin_internallinkdetect_6fa57e86' // Source: ../plugins/internal-link-detect.client (mode: 'client')
import nuxt_plugin_vuemasonry_087bf870 from 'nuxt_plugin_vuemasonry_087bf870' // Source: ../plugins/vue-masonry (mode: 'all')
import nuxt_plugin_scrollbehavior_57a321c8 from 'nuxt_plugin_scrollbehavior_57a321c8' // Source: ../plugins/scroll-behavior (mode: 'client')
import nuxt_plugin_api_5e4622e4 from 'nuxt_plugin_api_5e4622e4' // Source: ../plugins/api (mode: 'all')
import nuxt_plugin_featureflag_b090ab4e from 'nuxt_plugin_featureflag_b090ab4e' // Source: ../plugins/feature-flag (mode: 'all')
import nuxt_plugin_mediaobject_0fd64fbe from 'nuxt_plugin_mediaobject_0fd64fbe' // Source: ../plugins/media-object (mode: 'all')
import nuxt_plugin_userlocale_56f40166 from 'nuxt_plugin_userlocale_56f40166' // Source: ../plugins/user-locale (mode: 'all')
import nuxt_plugin_route_338f5eda from 'nuxt_plugin_route_338f5eda' // Source: ../plugins/route (mode: 'all')
import nuxt_plugin_auth_c05e1b32 from 'nuxt_plugin_auth_c05e1b32' // Source: ./auth.js (mode: 'all')
import nuxt_plugin_partner_90e7dedc from 'nuxt_plugin_partner_90e7dedc' // Source: ../plugins/partner (mode: 'client')
import nuxt_plugin_meta_af34cae6 from 'nuxt_plugin_meta_af34cae6' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = false

async function createApp(ssrContext, config = {}) {
  const store = null
  const router = await createRouter(ssrContext, config, { store })

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: function() {
    let i18nHead = { htmlAttr: {}, meta: [], link: [] };
    if (this.$nuxtI18nHead) {
      i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    }
    return {
      title: 'Clozen',
      htmlAttrs: {
        ...i18nHead.htmlAttrs
      },
      meta: [
      {
        charset: 'utf-8'
      },
      {
        name: 'viewport',
        content:
        'width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, minimal-ui'
      },
      {
        hid: 'description',
        name: 'description',
        content: ''
      },
      {
        hid: 'facebook-domain-verification',
        name: 'facebook-domain-verification',
        content: 'p367po88ufzmkuf1gs1jr62vppd8lk'
      },
      ...i18nHead.meta],

      link: [
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/apple-touch-icon.png'
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: '/favicon-32x32.png'
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: '/favicon-16x16.png'
      },
      {
        rel: 'manifest',
        href: '/site.webmanifest'
      },
      {
        rel: 'mask-icon',
        color: '#f4a650',
        href: '/safari-pinned-tab.svg'
      },
      {
        rel: 'msapplication-TileColor',
        content: '#da532c'
      },
      {
        rel: 'theme-color',
        content: '#ffffff'
      },
      ...i18nHead.link]
    };
  },

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_39a43726 === 'function') {
    await nuxt_plugin_sentryserver_39a43726(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_7d7472e5 === 'function') {
    await nuxt_plugin_sentryclient_7d7472e5(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_1a0bccbb === 'function') {
    await nuxt_plugin_plugin_1a0bccbb(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_templatesplugin84f09a68_3027c4e9 === 'function') {
    await nuxt_plugin_templatesplugin84f09a68_3027c4e9(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_templatesplugin58e22f80_1472843d === 'function') {
    await nuxt_plugin_templatesplugin58e22f80_1472843d(app.context, inject)
  }

  if (typeof nuxt_plugin_vuesocialsharingplugin_61aaaa22 === 'function') {
    await nuxt_plugin_vuesocialsharingplugin_61aaaa22(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_0553bc34 === 'function') {
    await nuxt_plugin_deviceplugin_0553bc34(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_6a7f0b51 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_6a7f0b51(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_7ca9af80 === 'function') {
    await nuxt_plugin_vuescrollto_7ca9af80(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_380c304a === 'function') {
    await nuxt_plugin_portalvue_380c304a(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_328844ae === 'function') {
    await nuxt_plugin_axios_328844ae(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_2223109c === 'function') {
    await nuxt_plugin_pluginutils_2223109c(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_b9a03f06 === 'function') {
    await nuxt_plugin_pluginrouting_b9a03f06(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_778fb7dc === 'function') {
    await nuxt_plugin_pluginmain_778fb7dc(app.context, inject)
  }

  if (typeof nuxt_plugin_distplugind9d6da42_2e59e8a5 === 'function') {
    await nuxt_plugin_distplugind9d6da42_2e59e8a5(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_ade8e6f6 === 'function') {
    await nuxt_plugin_workbox_ade8e6f6(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_b8b302d0 === 'function') {
    await nuxt_plugin_metaplugin_b8b302d0(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_af63b7e8 === 'function') {
    await nuxt_plugin_iconplugin_af63b7e8(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (typeof nuxt_plugin_promisestack_1f7078c0 === 'function') {
    await nuxt_plugin_promisestack_1f7078c0(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtstore_30e92d35 === 'function') {
    await nuxt_plugin_nuxtstore_30e92d35(app.context, inject)
  }

  if (typeof nuxt_plugin_global_6441a2ce === 'function') {
    await nuxt_plugin_global_6441a2ce(app.context, inject)
  }

  if (typeof nuxt_plugin_mediaquery_6c026069 === 'function') {
    await nuxt_plugin_mediaquery_6c026069(app.context, inject)
  }

  if (typeof nuxt_plugin_bus_5e46274a === 'function') {
    await nuxt_plugin_bus_5e46274a(app.context, inject)
  }

  if (typeof nuxt_plugin_filters_98405076 === 'function') {
    await nuxt_plugin_filters_98405076(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_6e5ad03a === 'function') {
    await nuxt_plugin_veevalidate_6e5ad03a(app.context, inject)
  }

  if (typeof nuxt_plugin_vuescroll_0c9cb62c === 'function') {
    await nuxt_plugin_vuescroll_0c9cb62c(app.context, inject)
  }

  if (typeof nuxt_plugin_vuejsmodal_f50827f4 === 'function') {
    await nuxt_plugin_vuejsmodal_f50827f4(app.context, inject)
  }

  if (typeof nuxt_plugin_vscrolllock_a40204c4 === 'function') {
    await nuxt_plugin_vscrolllock_a40204c4(app.context, inject)
  }

  if (typeof nuxt_plugin_vuelazyload_211b2937 === 'function') {
    await nuxt_plugin_vuelazyload_211b2937(app.context, inject)
  }

  if (typeof nuxt_plugin_vueobservevisibility_2d7eef00 === 'function') {
    await nuxt_plugin_vueobservevisibility_2d7eef00(app.context, inject)
  }

  if (typeof nuxt_plugin_vuetippy_1467d320 === 'function') {
    await nuxt_plugin_vuetippy_1467d320(app.context, inject)
  }

  if (typeof nuxt_plugin_svgxuse_0493c29a === 'function') {
    await nuxt_plugin_svgxuse_0493c29a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_mercure_1fa522c9 === 'function') {
    await nuxt_plugin_mercure_1fa522c9(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetoastification_bd752598 === 'function') {
    await nuxt_plugin_vuetoastification_bd752598(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_piniapluginpersist_06683d2e === 'function') {
    await nuxt_plugin_piniapluginpersist_06683d2e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_internallinkdetect_6fa57e86 === 'function') {
    await nuxt_plugin_internallinkdetect_6fa57e86(app.context, inject)
  }

  if (typeof nuxt_plugin_vuemasonry_087bf870 === 'function') {
    await nuxt_plugin_vuemasonry_087bf870(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_scrollbehavior_57a321c8 === 'function') {
    await nuxt_plugin_scrollbehavior_57a321c8(app.context, inject)
  }

  if (typeof nuxt_plugin_api_5e4622e4 === 'function') {
    await nuxt_plugin_api_5e4622e4(app.context, inject)
  }

  if (typeof nuxt_plugin_featureflag_b090ab4e === 'function') {
    await nuxt_plugin_featureflag_b090ab4e(app.context, inject)
  }

  if (typeof nuxt_plugin_mediaobject_0fd64fbe === 'function') {
    await nuxt_plugin_mediaobject_0fd64fbe(app.context, inject)
  }

  if (typeof nuxt_plugin_userlocale_56f40166 === 'function') {
    await nuxt_plugin_userlocale_56f40166(app.context, inject)
  }

  if (typeof nuxt_plugin_route_338f5eda === 'function') {
    await nuxt_plugin_route_338f5eda(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_c05e1b32 === 'function') {
    await nuxt_plugin_auth_c05e1b32(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_partner_90e7dedc === 'function') {
    await nuxt_plugin_partner_90e7dedc(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_af34cae6 === 'function') {
    await nuxt_plugin_meta_af34cae6(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
