<template>
  <div class="reinsurance">
    <div class="reinsurance__block">
      <div to="#" class="reinsurance__block__content">
        <SecureSvg class="reinsurance__block__content__icon" />
        <span class="reinsurance__block__content__text">{{
          $t('reinsurance.block.transactions')
        }}</span>
      </div>
    </div>
    <div class="reinsurance__block">
      <div to="#" class="reinsurance__block__content">
        <ReturnSvg class="reinsurance__block__content__icon" />
        <span class="reinsurance__block__content__text">{{
          $t('reinsurance.block.refund')
        }}</span>
      </div>
    </div>
    <div class="reinsurance__block">
      <div to="#" class="reinsurance__block__content">
        <PackageSvg class="reinsurance__block__content__icon" />
        <span class="reinsurance__block__content__text">{{
          $t('reinsurance.block.packaging')
        }}</span>
      </div>
    </div>
    <div class="reinsurance__block">
      <div class="reinsurance__block__content">
        <SupportSvg class="reinsurance__block__content__icon" />
        <span class="reinsurance__block__content__text">{{
          $t('reinsurance.block.support')
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import SecureSvg from '~/assets/img/secure.svg?inline';
import PackageSvg from '~/assets/img/package.svg?inline';
import ReturnSvg from '~/assets/img/return.svg?inline';
import SupportSvg from '~/assets/img/support.svg?inline';

export default {
  name: 'Reinsurance',
  components: {
    SecureSvg,
    PackageSvg,
    SupportSvg,
    ReturnSvg
  }
};
</script>

<style lang="scss" src="./reinsurance.scss" scoped></style>
