var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button",class:{
    'button--outline': _vm.outline,
    'button--disabled': _vm.disabled,
    'button--width-auto': _vm.widthAuto,
    [`button--size-${_vm.size}`]: _vm.size,
    [`button--theme-${_vm.theme}`]: _vm.theme
  },on:{"click":_vm.handleClick}},[(_vm.to)?[(_vm.isInternal)?_c('nuxt-link',{attrs:{"to":_vm.to}},[_vm._t("default")],2):_c('a',{attrs:{"href":_vm.to,"target":_vm.target,"rel":"noopener noreferrer"}},[_vm._t("default")],2)]:_c('div',[_vm._t("default")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }