import Vue from 'vue';

Vue.component('WithRoot', {
  functional: true,
  props: {
    show: { type: Boolean, default: true }
  },
  render(h, ctx) {
    const children = ctx.children.filter((vnode) => vnode.tag);
    if (children.length !== 1) {
      console.warn('this component accepts only one root node in its slot');
    }
    if (ctx.props.show) {
      return children[0];
    } else {
      return children[0].children;
    }
  }
});
