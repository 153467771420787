const middleware = {}

middleware['active-account'] = require('../middleware/active-account.js')
middleware['active-account'] = middleware['active-account'].default || middleware['active-account']

middleware['global'] = require('../middleware/global.js')
middleware['global'] = middleware['global'].default || middleware['global']

middleware['i18n'] = require('../middleware/i18n.js')
middleware['i18n'] = middleware['i18n'].default || middleware['i18n']

middleware['promise-resolver'] = require('../middleware/promise-resolver.js')
middleware['promise-resolver'] = middleware['promise-resolver'].default || middleware['promise-resolver']

export default middleware
