<template>
  <transition name="fade" mode="out-in">
    <div v-if="isMounted" class="sign-up">
      <h2 class="sign-up__heading">
        <template v-if="!finalize">
          {{ $t('auth.register.heading') }}
        </template>
        <template v-else>
          {{ $t('auth.register.finalize-heading') }}
        </template>
      </h2>
      <div v-if="error" class="sign-up__error">{{ error }}</div>
      <ValidationObserver ref="registerForm" tag="div" v-slot="{ invalid }">
        <form
          class="sign-up__body d-flex flex-column"
          @submit.prevent="onRegister"
        >
          <InputRow
            v-model="username"
            type="text"
            :label="$t('auth.register.username.label')"
            :rules="rules.username"
            :hint="$t('auth.register.username.hint')"
          />
          <InputRow
            v-show="!finalize"
            v-model="email"
            type="email"
            :label="$t('auth.register.email.label')"
            :rules="rules.email"
          />
          <InputRow
            v-model="password"
            type="password"
            :label="$t('auth.register.password.label')"
            :rules="rules.password"
            :hint="$t('auth.register.password.hint')"
          />
          <ValidationProvider
            tag="div"
            v-slot="{ errors }"
            rules="required"
            class="d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center sign-up__input__wrapper"
          >
            <div class="sign-up__label">
              {{ $t('auth.register.locale.label') }}
            </div>
            <div class="sign-up__input">
              <Multiselect
                v-model="locale"
                :options="options.locales"
                track-by="code"
                label="name"
                :searchable="false"
                :placeholder="$t('auth.register.locale.placeholder')"
                select-label=""
                selected-label=""
                deselect-label=""
              />
              <transition name="slide-vertical">
                <div v-if="errors.length" class="sign-up__error">
                  {{ errors[0] }}
                </div>
              </transition>
            </div>
          </ValidationProvider>
          <div class="sign-up__toggle">
            <label for="optInNewsletter" class="sign-up__toggle__label">
              {{ $t('auth.register.optInNewsletter.label') }}
            </label>
            <Toggle v-model="optInNewsletter" id="optInNewsletter" />
          </div>
          <div class="sign-up__toggle">
            <label
              for="acceptTerms"
              class="sign-up__toggle__label"
              v-html="
                $t('auth.register.acceptTerms.label', {
                  termsLink: localePath('TermsAndConditions'),
                  privacyLink: localePath('PrivacyPolicy'),
                  catalogLink: $t('auth.register.acceptTerms.catalogLink'),
                  communityLink: $t('auth.register.acceptTerms.communityLink')
                })
              "
            />
            <ValidationProvider tag="div" rules="required" v-slot="{ errors }">
              <Toggle v-model="acceptTerms" id="acceptTerms" />
              <transition name="slide-vertical">
                <div v-if="errors.length" class="sign-up__error">
                  {{ errors[0] }}
                </div>
              </transition>
            </ValidationProvider>
          </div>
          <div
            v-if="socialLoginEnabled && !finalize"
            class="sign-up__social-login"
          >
            <!-- <div class="sign-up__social-login__heading">
              {{ $t('auth.register.login-with') }}
            </div> -->
            <a
              href="#"
              class="sign-up__social-login__btn sign-up__social-login__btn--facebook"
              @click="signUpWith($event, 'facebook')"
            >
              <span class="icon-wrapper">
                <FacebookSvg />
              </span>
              <span class="network-name">
                {{ $t('auth.social.label', { network: 'Facebook' }) }}
              </span>
            </a>
            <a
              href="#"
              class="sign-up__social-login__btn sign-up__social-login__btn--google"
              @click="signUpWith($event, 'google')"
            >
              <span class="icon-wrapper">
                <GoogleSvg />
              </span>
              <span class="network-name">
                {{ $t('auth.social.label', { network: 'Google' }) }}
              </span>
            </a>
            <a
              href="#"
              class="sign-up__social-login__btn sign-up__social-login__btn--apple"
              @click="signInWith($event, 'apple')"
            >
              <span class="icon-wrapper">
                <AppleSvg />
              </span>
              <span class="network-name">
                {{ $t('auth.social.label', { network: 'Apple' }) }}
              </span>
            </a>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <template v-if="!finalize">
                {{ $t('components.authentication.i-have-an') }}
                <a
                  href="javascript:void(0)"
                  class="sign-up__step-switch"
                  @click="$emit('step:switch', 'sign-in')"
                >
                  {{ $t('components.authentication.account') }}
                </a>
              </template>
            </div>
            <Button :disabled="invalid || !acceptTerms" @click="onRegister">
              {{ $t('auth.register.cta') }}
            </Button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </transition>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import InputRow from '@/components/Form/InputRow/InputRow';
import Button from '@/components/Button/Button';
import Toggle from '@/components/Form/Toggle/Toggle';
import FacebookSvg from '~/assets/img/share/facebook.svg?inline';
import GoogleSvg from '~/assets/img/share/google.svg?inline';
import AppleSvg from '~/assets/img/share/apple.svg?inline';
import { mapActions } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { captureErrorMixin } from '@/mixins/captureError';
import Multiselect from 'vue-multiselect';
import isNil from 'lodash.isnil';
import isEmpty from 'lodash.isempty';

export default {
  name: 'SignUp',
  components: {
    ValidationProvider,
    ValidationObserver,
    InputRow,
    Button,
    Toggle,
    Multiselect,
    FacebookSvg,
    GoogleSvg,
    AppleSvg
  },
  mixins: [captureErrorMixin],
  props: {
    finalize: {
      type: String,
      default: null
    }
  },
  data: () => ({
    isMounted: true,
    socialLoginEnabled: false,
    registerTutorialEnabled: false,
    error: null,
    username: '',
    email: '',
    password: '',
    optInNewsletter: false,
    acceptTerms: false,
    locale: null,
    options: {
      locales: [
        { code: 'fr', name: 'Français' },
        { code: 'en', name: 'Anglais' },
        { code: 'de', name: 'Allemand' }
      ]
    },
    rules: {
      username: 'required',
      email: 'required|email',
      password: 'required|password'
    }
  }),
  watch: {
    finalize(val) {
      this.email = val;
    }
  },
  async created() {
    this.socialLoginEnabled = await this.$feature.isActive('social-login');
    this.registerTutorialEnabled = await this.$feature.isActive(
      'register-tutorial'
    );
  },
  async mounted() {
    this.isMounted = true;
    const locale = this.$i18n.getBrowserLocale();
    if (!isNil(locale)) {
      this.locale = this.options.locales.find((l) => l.code === locale);
    }
    this.finalize && (this.email = this.finalize);
  },
  methods: {
    ...mapActions(useAuthStore, {
      doRegister: 'register'
    }),
    async onRegister() {
      const valid = await this.$refs.registerForm.validate();
      if (!valid) return;
      this.$emit('sign-up:before');
      const partner = this.$partner.isSwibecoPartner() ? 'swibeco' : null;
      await this.try(
        async () => {
          const locale = this.locale?.code || null;
          const registered = await this.doRegister(
            this.username,
            this.email,
            this.password,
            locale,
            partner,
            this.optInNewsletter
          );
          if (registered) {
            this.$emit('sign-up:success');
            const { data } = await this.$auth.loginWith('local', {
              data: {
                email: this.email,
                password: this.password
              }
            });
            if (data.token) {
              this.$emit('sign-in:success');
              if (!this.registerTutorialEnabled) {
                if (!isEmpty(this.$auth.$state?.redirect)) {
                  this.$router.push(this.$auth.$state.redirect);
                } else {
                  this.$router.push(
                    this.localePath({
                      name: 'Account-UserSettings',
                      query: { tab: 'settings' }
                    })
                  );
                }
              } else {
                this.$router.push(this.localePath({ name: 'Welcome' }));
              }
            }
          }
        },
        (err) => {
          const { data } = err.response || {};
          if (isNil(data)) return;
          this.error = data['hydra:description'] || data.message || null;
        }
      );
      this.$emit('sign-up:after');
    },
    async signUpWith(event, provider) {
      event.preventDefault();

      const url = new URL(
        `/social/${provider}/login`,
        process.env.NUXT_ENV_BROWSER_API_BASE_URL
      );
      document.location.href = url.href;
    }
  }
};
</script>

<style lang="scss" scoped src="./sign-up.scss"></style>
