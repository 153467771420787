<template>
  <modal
    name="auth-modal"
    adaptive
    focusTrap
    width="480"
    height="auto"
    @closed="onModalClosed"
  >
    <div class="auth-modal">
      <transition name="fade" mode="out-in">
        <div v-if="step == 'sign-in'" key="sign-in">
          <SignIn
            @step:switch="step = $event"
            @sign-in:before="loading = true"
            @sign-in:after="loading = false"
            @sign-in:success="hide"
          />
        </div>
        <div v-else-if="step == 'sign-up'" key="sign-up">
          <SignUp
            :finalize="signupFinalize"
            @step:switch="step = $event"
            @sign-up:before="loading = true"
            @sign-up:after="loading = false"
            @sign-up:success="hide"
          />
        </div>
        <div
          v-else-if="step == 'password-reset-request'"
          key="password-reset-request"
        >
          <PasswordResetRequest
            @step:switch="step = $event"
            @password-reset-request:before="loading = true"
            @password-reset-request:after="loading = false"
            @password-reset-request:success="onPasswordResetRequestSuccess"
          />
        </div>
      </transition>
    </div>
    <Loader :value="loading" />
  </modal>
</template>

<script>
import Loader from '@/components/Loading/Loader';
import SignIn from './SignIn';
import SignUp from './SignUp';
import PasswordResetRequest from './PasswordResetRequest';

export default {
  name: 'LoginModal',
  components: { Loader, SignIn, SignUp, PasswordResetRequest },
  data() {
    return {
      step: 'sign-in',
      email: '',
      username: '',
      password: '',
      error: null,
      loading: false,
      signupFinalize: null,
      rules: {
        username: 'required',
        email: 'required|email',
        password: 'required'
      }
    };
  },
  mounted() {
    this.$bus.$on('auth:login:show', this.show);
    this.$bus.$on('auth:login:hide', this.hide);
    this.$bus.$on('auth:sign-up:finalize', this.onSignUpFinalize);
  },
  beforeDestroy() {
    this.$bus.$off('auth:login:show', this.show);
    this.$bus.$off('auth:login:hide', this.hide);
    this.$bus.$off('auth:sign-up:finalize', this.onSignUpFinalize);
  },
  methods: {
    show() {
      this.$modal.show('auth-modal');
    },
    hide() {
      this.$modal.hide('auth-modal');
    },
    onPasswordResetRequestSuccess() {
      this.$toast.success(this.$t('auth.password-reset-request.success'));
      this.hide();
    },
    onSignUpFinalize(email) {
      this.signupFinalize = email;
      this.step = 'sign-up';
      this.$modal.show('auth-modal');
    },
    onModalClosed() {
      this.step = 'sign-in';
      this.signupFinalize = null;
    }
  }
};
</script>

<style lang="scss" scoped src="./auth-modal.scss"></style>
