import { doRequest } from '@/api/utils';
import isNil from 'lodash.isnil';

export async function getMegamenu($axios) {
  const { data } = await doRequest($axios, 'GET', '/megamenu');
  return data['hydra:member'] || null;
}

export async function getAll($axios) {
  const { data } = await doRequest($axios, 'GET', '/taxons');
  let lastPage = data?.['hydra:view']?.['hydra:last'];
  if (isNil(lastPage)) {
    return data['hydra:member'] || null;
  }
  lastPage = parseInt(lastPage.replace(/[^\d]+/, ''));
  if (lastPage <= 1) {
    return data['hydra:member'] || null;
  }
  const all = data['hydra:member'] || [];
  const promises = [];
  for (let page = 2; page <= lastPage; page++) {
    promises.push(
      doRequest($axios, 'GET', '/taxons', {}, { params: { page } })
        .then((resp) => resp.data?.['hydra:member'] || [])
        .catch((_) => [])
    );
  }
  const restOfTaxons = await Promise.all(promises);
  for (const taxons of restOfTaxons) {
    all.push(...taxons);
  }
  return all;
}

export async function getCategory($axios, slug) {
  const { data } = await doRequest($axios, 'GET', `/categories/${slug}`);
  return data;
}

export async function getCategoryItems($axios, slug, params, algolia = false) {
  const endpoint = !algolia
    ? `/categories/${slug}/items`
    : `/categories/${slug}/alg/items`;
  const { data, headers } = await doRequest(
    $axios,
    'GET',
    endpoint,
    {},
    { params }
  );
  const queryID = headers['x-algolia-queryid'] || null;
  return { data, queryID };
}

export async function getCategoryAggs($axios, slug, params, algolia = false) {
  const endpoint = !algolia
    ? `/categories/${slug}/aggs`
    : `/categories/${slug}/alg/aggs`;
  const { data } = await doRequest($axios, 'GET', endpoint, {}, { params });
  return data;
}

export async function getTaxon($axios, taxonId) {
  const { data } = await doRequest($axios, 'GET', `/taxons/${taxonId}`);
  return data;
}

export async function getTaxonSizeCategories($axios, taxonId) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/taxons/${taxonId}/size-categories`
  );
  return data['hydra:member'] || null;
}
