export default async (_, inject) => {
  const promises = [];
  inject('promiseStack', {
    enqueue(promise) {
      promises.push(promise);
    },
    async all() {
      const data = await Promise.all(promises);
      this.clear();
      return data;
    },
    clear() {
      promises.splice(0, promises.length);
    }
  });
};
