<template>
  <transition name="fade" mode="out-in">
    <div v-if="isMounted" class="sign-in">
      <h2 class="sign-in__heading">
        {{ $t('auth.login.heading') }}
      </h2>
      <div v-if="error" class="sign-in__error">{{ error }}</div>
      <ValidationObserver
        v-if="!twoFactorStep"
        ref="loginForm"
        tag="div"
        v-slot="{ invalid }"
      >
        <form
          class="sign-in__body d-flex flex-column"
          @submit.prevent="onLogin"
        >
          <InputRow
            v-model="email"
            type="email"
            :label="$t('auth.login.email.label')"
            :rules="rules.email"
          />
          <InputRow
            v-model="password"
            type="password"
            :label="$t('auth.login.password.label')"
            :rules="rules.password"
            @keyup.enter="onLogin"
          />
          <div class="sign-in__password-forgotten">
            <a
              href="javascript:void(0)"
              class="sign-in__step-switch"
              @click="$emit('step:switch', 'password-reset-request')"
            >
              {{ $t('components.authentication.password-forgotten') }}
            </a>
          </div>
          <div
            v-if="socialLoginEnabled && !finalize"
            class="sign-in__social-login"
          >
            <!-- <div class="sign-in__social-login__heading">
              {{ $t('auth.login.login-with') }}
            </div> -->
            <a
              href="#"
              class="sign-in__social-login__btn sign-in__social-login__btn--facebook"
              @click="signInWith($event, 'facebook')"
            >
              <span class="icon-wrapper">
                <FacebookSvg />
              </span>
              <span class="network-name">
                {{ $t('auth.social.label', { network: 'Facebook' }) }}
              </span>
            </a>
            <a
              href="#"
              class="sign-in__social-login__btn sign-in__social-login__btn--google"
              @click="signInWith($event, 'google')"
            >
              <span class="icon-wrapper">
                <GoogleSvg />
              </span>
              <span class="network-name">
                {{ $t('auth.social.label', { network: 'Google' }) }}
              </span>
            </a>
            <a
              href="#"
              class="sign-in__social-login__btn sign-in__social-login__btn--apple"
              @click="signInWith($event, 'apple')"
            >
              <span class="icon-wrapper">
                <AppleSvg />
              </span>
              <span class="network-name">
                {{ $t('auth.social.label', { network: 'Apple' }) }}
              </span>
            </a>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div>
              {{ $t('components.authentication.sign-up-with') }}
              <a
                href="javascript:void(0)"
                class="sign-in__step-switch"
                @click="$emit('step:switch', 'sign-up')"
              >
                {{ $t('components.authentication.email') }}
              </a>
            </div>
            <Button :disabled="invalid" @click="onLogin">
              {{ $t('auth.login.cta') }}
            </Button>
          </div>
        </form>
      </ValidationObserver>
      <ValidationObserver
        v-else
        ref="twoFactorCode"
        tag="div"
        v-slot="{ invalid }"
      >
        <p v-if="twoFactorMethod">
          {{
            $t(`components.authentication.two-factor-hint.${twoFactorMethod}`)
          }}
        </p>
        <p v-else>
          {{ $t('components.authentication.two-factor-hint.default') }}
        </p>
        <InputRow
          ref="twoFactorAuthCode"
          v-model="authCode"
          type="text"
          :placeholder="
            $t(
              'components.two-factor-authentication-modal.providers.google.code'
            )
          "
          rules="required|2fa_auth_code"
          @keyup.enter="validateCode"
        />
        <div class="d-flex justify-content-end">
          <Button :disabled="invalid" @click="validateCode">
            {{ $t('components.authentication.validate') }}
          </Button>
        </div>
      </ValidationObserver>
    </div>
  </transition>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import InputRow from '@/components/Form/InputRow/InputRow';
import Button from '@/components/Button/Button';
import FacebookSvg from '~/assets/img/share/facebook.svg?inline';
import GoogleSvg from '~/assets/img/share/google.svg?inline';
import AppleSvg from '~/assets/img/share/apple.svg?inline';
import { captureErrorMixin } from '@/mixins/captureError';
import isEmpty from 'lodash.isempty';
import { mapState, mapActions } from 'pinia';
import { logIn } from '@/api/auth';
import { useAuthStore } from '@/stores/auth';
import { twoFactorCheck } from '@/api/auth';

export default {
  name: 'SignIn',
  components: {
    ValidationObserver,
    InputRow,
    Button,
    FacebookSvg,
    GoogleSvg,
    AppleSvg
  },
  mixins: [captureErrorMixin],
  props: {
    finalize: {
      type: String,
      default: null
    }
  },
  data: () => ({
    isMounted: true,
    socialLoginEnabled: false,
    error: null,
    email: '',
    password: '',
    rules: {
      email: 'required|email',
      password: 'required'
    },
    twoFactorStep: false,
    twoFactorMethod: null,
    authCode: ''
  }),
  computed: {
    ...mapState(useAuthStore, ['redirectedFrom', 'sessId'])
  },
  async created() {
    const active = await this.$feature.isActive('social-login');
    this.socialLoginEnabled = active;
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    ...mapActions(useAuthStore, ['setSessId']),
    async onLogin() {
      this.$refs.loginForm.validate().then(async (success) => {
        if (!success) return;
        this.$emit('sign-in:before');
        await this.try(
          async () => {
            const email = this.email.toLowerCase();
            const { data, headers } = await this.$api.doRequest(
              logIn,
              email,
              this.password
            );
            if (!isEmpty(headers['x-sessid'])) {
              this.setSessId(headers['x-sessid']);
            }
            if ('two_factor_complete' in data && !data.two_factor_complete) {
              this.twoFactorStep = true;
              this.twoFactorMethod = data['2fa_method'] || null;
            } else if (data.token && data.refresh_token) {
              await this.$auth.setUserToken(data.token, data.refresh_token);
              this.$emit('sign-in:success');
              this.$bus.$emit('sign-in:success');
              if (!isEmpty(this.redirectedFrom)) {
                this.$router.push(this.redirectedFrom);
              } else if (!isEmpty(this.$auth.$state.redirect)) {
                this.$router.push(this.$auth.$state.redirect);
              } else {
                this.$router.push(this.localePath({ name: 'index' }));
              }
            }
          },
          (err) =>
            (this.error =
              err.response?.data?.message ||
              err.response?.data?.detail ||
              null),
          undefined,
          { showToast: false }
        );
        this.$emit('sign-in:after');
      });
    },
    async signInWith(event, provider) {
      event.preventDefault();

      const url = new URL(
        `/social/${provider}/login`,
        process.env.NUXT_ENV_BROWSER_API_BASE_URL
      );
      document.location.href = url.href;
    },
    async validateCode() {
      const valid = await this.$refs.twoFactorCode.validate();
      if (!valid) return;

      await this.try(
        async () => {
          const { token, refresh_token } = await this.$api.doRequest(
            twoFactorCheck,
            this.authCode,
            this.sessId
          );
          if (token && refresh_token) {
            await this.$auth.setUserToken(token, refresh_token);
            this.$emit('sign-in:success');
            this.$bus.$emit('sign-in:success');
            if (!isEmpty(this.redirectedFrom)) {
              this.$router.push(this.redirectedFrom);
            } else if (!isEmpty(this.$auth.$state.redirect)) {
              this.$router.push(this.$auth.$state.redirect);
            } else {
              this.$router.push(this.localePath({ name: 'index' }));
            }
          }
        },
        (err) =>
          (this.error =
            err.response?.data?.message ||
            this.$t('components.authentication.invalid-verification-code')),
        undefined,
        { showToast: false }
      );
    }
  }
};
</script>

<style lang="scss" scoped src="./sign-in.scss"></style>
