import { defineStore } from 'pinia';

export const useGlobalStore = defineStore('global', {
  state: () => ({
    searchTerms: null,
    globalLoading: false
  }),
  actions: {
    setGlobalLoading(loading) {
      this.$patch({ globalLoading: loading });
    },
    clearSearchTerms() {
      this.$patch({ searchTerms: null });
    },
    setSearchTerms(searchTerms) {
      this.$patch({ searchTerms });
    }
  }
});
