<template>
  <input
    ref="input"
    class="form-input"
    :class="{ 'form-input--has-error': hasError }"
    :type="type"
    :value="value"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    v-on="inputListeners"
  />
</template>

<script>
export default {
  props: {
    type: { type: String, default: 'text' },
    value: { type: String },
    hasError: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    autocomplete: { type: String, default: null }
  },
  computed: {
    inputListeners() {
      const vm = this;
      return Object.assign({}, this.$listeners, {
        input: (event) => vm.$emit('input', event.target.value, event),
        keydown: (event) => vm.$emit('keydown', event),
        keyup: (event) => vm.$emit('keyup', event),
        focus: (event) => vm.$emit('focus', event),
        blur: (event) => vm.$emit('blur', event)
      });
    }
  },
  methods: {
    focus() {
      this.$refs.input?.focus();
    }
  }
};
</script>

<style lang="scss" scoped src="./form-input.scss"></style>
