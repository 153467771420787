<template>
  <GenericModal ref="modal" width="420" :body-padding="15" @closed="onClosed">
    <template #header>
      {{ $t('components.two-factor-reminder-modal.heading') }}
    </template>
    <div class="two-factor-reminder__content">
      {{ $t('components.two-factor-reminder-modal.content') }}

      <div class="two-factor-reminder__content__actions">
        <Button outline @click="later">
          {{ $t('components.two-factor-reminder-modal.later') }}
        </Button>
        <Button @click="goToSettings">
          {{ $t('components.two-factor-reminder-modal.cta') }}
        </Button>
      </div>
    </div>
  </GenericModal>
</template>

<script>
import GenericModal from '@/components/Modal/GenericModal';
import Button from '@/components/Button/Button';

export default {
  name: 'TwoFactorReminderModal',
  components: { GenericModal, Button },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    onClosed() {
      window.localStorage.setItem('2fa:reminder:last-closed', Date.now());
    },
    later() {
      this.hide();
    },
    goToSettings() {
      this.hide();
      this.$router.push(
        this.localePath({
          name: 'Account-UserSettings',
          query: { tab: 'settings' },
          hash: '#security'
        })
      );
    }
  }
};
</script>

<style lang="scss" src="./two-factor-reminder-modal.scss" scoped></style>
