import isEmpty from 'lodash.isempty';
import isNil from 'lodash.isnil';
import { updateUser } from '@/api/user';

export default async function (ctx, inject) {
  const {
    app: { router },
    route,
    $api,
    $auth
  } = ctx;

  let partner = route.query?.partner || null;
  if (isEmpty(partner) && window.localStorage) {
    partner = window.localStorage.getItem('_partner');
  }

  router.beforeEach(async (to, from, next) => {
    if (!isEmpty(partner)) {
      if ($auth.loggedIn) {
        if (!isNil($auth.user.partner)) {
          window.localStorage.removeItem('_partner');
        } else {
          try {
            await $api.doRequest(updateUser, $auth.user.id, { partner });
            window.localStorage.removeItem('_partner');
          } catch {}
        }
      } else {
        window.localStorage && window.localStorage.setItem('_partner', partner);
      }
    }
    next();
  });

  inject('partner', {
    isPartner() {
      return (
        !isEmpty($auth.user?.partner) ||
        (window.localStorage &&
          null !== window.localStorage.getItem('_partner'))
      );
    },
    isSwibecoPartner() {
      return (
        'swibeco' === $auth.user?.partner ||
        (window.localStorage &&
          'swibeco' === window.localStorage.getItem('_partner'))
      );
    }
  });
}
