import { defineStore } from 'pinia';
import { getMegamenu, getAll } from '@/api/category';
import { extractUuid } from '@/misc/string';
import isNil from 'lodash.isnil';
import isEmpty from 'lodash.isempty';
import cloneDeep from 'lodash.clonedeep';

export const useMenuStore = defineStore('menu', {
  state: () => ({
    entries: null,
    flattened: null
  }),
  getters: {
    getCategory(state) {
      return (id) => state.flattened[id] || null;
    }
  },
  actions: {
    async nuxtServerInit() {
      const { $api } = this.$nuxt;
      if (!isNil(this.entries)) return;
      // Fetch megamenu
      try {
        const megamenu = await $api.doRequest(getMegamenu);
        const sorter = (entries) => {
          if (isNil(entries)) return;
          entries.forEach((entry) => {
            Array.isArray(entry.children) && sorter(entry.children);
          });
          entries.sort((a, b) => a.position - b.position);
        };
        sorter(megamenu);
        this.$patch({ entries: megamenu });
      } catch (err) {
        console.error(err);
      }
      // Fetch all categories (for crumbs builder)
      try {
        const all = await $api.doRequest(getAll);
        const allIndexed = all.reduce((acc, entry) => {
          acc[entry.id] = entry;
          return acc;
        }, {});
        this.$patch({ flattened: allIndexed });
      } catch (err) {
        console.error(err);
      }
    },
    getCrumbs(category) {
      if (isNil(category)) return [];
      if (isNil(this.flattened)) return [];
      if (typeof category === 'string') {
        const uid = extractUuid(category);
        category = this.flattened[uid];
      } else if (typeof category['id'] === 'string') {
        const uid = category['id'];
        category = this.flattened[uid];
      } else if (typeof category['@id'] === 'string') {
        const uid = extractUuid(category['@id']);
        category = this.flattened[uid];
      }
      if (isNil(category)) return [];
      const selfCrumb = {
        title: category.name,
        route: { name: 'Category', params: { slug: category.slug } }
      };
      if (isNil(category.parent)) {
        return [selfCrumb];
      }
      return [...this.getCrumbs(category.parent), selfCrumb];
    },
    buildEntries(categoryIds) {
      if (isEmpty(this.entries)) return null;
      const entriesCopy = cloneDeep(this.entries);
      const filterCategories = (categories) =>
        categories.filter((category) => {
          if (isEmpty(category.children)) {
            return categoryIds.includes(category.id);
          }
          category.children = filterCategories(category.children);
          return !isEmpty(category.children);
        });
      return filterCategories(entriesCopy);
    }
  }
});
