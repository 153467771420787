<template>
  <div v-if="loading" class="loading-page" @click.prevent.stop="">
    <div class="loading-page__loader" />
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false
  }),
  mounted() {
    this.$bus.$on('loading:start', this.start);
    this.$bus.$on('loading:finish', this.finish);
  },
  beforeDestroy() {
    this.$bus.$off('loading:start', this.start);
    this.$bus.$off('loading:finish', this.finish);
  },
  methods: {
    start() {
      this.loading = true;
    },
    finish() {
      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;

  @keyframes changeWidth {
    0% {
      width: 0;
      margin-left: 0;
    }

    50% {
      width: 100%;
      margin-left: 0;
    }

    100% {
      width: 0;
      margin-left: 100%;
    }
  }

  &__loader {
    height: 2px;
    animation-name: changeWidth;
    animation-duration: 5s;
    animation-iteration-count: infinite;
  }
}
</style>
