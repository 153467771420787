import { doRequest } from '@/api/utils';

export async function register(
  $axios,
  email,
  username,
  plainPassword,
  locale,
  partner,
  newsletter
) {
  const { data } = await doRequest($axios, 'POST', '/register', {
    email,
    username,
    plainPassword,
    locale,
    partner,
    newsletter
  });
  return data;
}

export async function logIn($axios, email, password) {
  const res = await doRequest($axios, 'POST', '/login-check', {
    email,
    password
  });
  return res;
}

export async function passwordResetRequest($axios, email) {
  const { data } = await doRequest($axios, 'POST', '/reset-password', {
    email
  });
  return data;
}

export async function passwordReset($axios, token, plainPassword) {
  const { data } = await doRequest(
    $axios,
    'POST',
    `/reset-password/reset/${token}`,
    { plainPassword }
  );
  return data;
}

export async function twoFactorCheck($axios, authCode, sessId) {
  const { data } = await doRequest(
    $axios,
    'POST',
    '/2fa-check',
    { authCode },
    { withCredentials: true, headers: { 'x-sessid': sessId } }
  );
  return data;
}

export async function impersonationForce($axios, email, userId) {
  const { data } = await doRequest($axios, 'POST', '/_impersonation/force', {
    email,
    impersonator: userId
  });
  return data;
}
