<template></template>

<script>
import isEmpty from 'lodash.isempty';

const AVAILABLE_LOCALE = ['en', 'de'];

export default {
  name: 'Axeptio',
  computed: {
    userLocale() {
      return this.$auth.user?.preferences?.locale;
    }
  },
  mounted() {
    if (process.client) {
      if (typeof window.axeptioSettings !== 'undefined') return;
      const settings = {
        clientId: process.env.NUXT_ENV_AXEPTIO_CLIENT_ID
      };
      if (isEmpty(settings.clientId)) return;
      if (AVAILABLE_LOCALE.includes(this.userLocale)) {
        settings.cookiesVersion = `clozen-${this.userLocale}`;
      } else {
        const lang =
          (this.$auth.loggedIn && this.$auth.user.preferences?.locale) ||
          this.$i18n?.locale ||
          navigator.language;
        let locale;
        try {
          locale = new Intl.Locale(lang);
        } catch {
          locale = { language: 'fr' };
        }
        if (AVAILABLE_LOCALE.includes(locale.language)) {
          settings.cookiesVersion = `clozen-${locale.language}`;
        }
      }
      window.axeptioSettings = settings;
      (function (d, s) {
        var t = d.getElementsByTagName(s)[0],
          e = d.createElement(s);
        e.async = true;
        e.src = '//static.axept.io/sdk.js';
        t.parentNode.insertBefore(e, t);
      })(document, 'script');
    }
  },
  watch: {
    userLocale(newLocale, oldLocale) {
      if (process.client && !isEmpty(oldLocale) && newLocale !== oldLocale) {
        window.location.reload();
      }
    }
  }
};
</script>
