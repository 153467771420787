<template>
  <div class="default-layout">
    <Header
      @menu:toggle="menuShown = !menuShown"
      @notifications:reload="reloadNotifications"
    />
    <div v-if="isMdMinus" class="mobile-menu">
      <MenuMobile
        :entries="menuEntries"
        :show="menuShown"
        @menu:close="menuShown = false"
      />
    </div>
    <div class="body">
      <nuxt />
    </div>
    <!-- <Reinsurance class="container" /> -->
    <Footer class="container" @notifications:reload="reloadNotifications" />
    <AuthModal />
    <Axeptio />
    <Loader :value="globalLoading" class="global-loader" />
  </div>
</template>

<script>
import Reinsurance from '@/components/Footer/components/Reinsurance/Reinsurance';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import MenuMobile from '@/components/Header/components/Menu/MenuMobile';
import AuthModal from '@/components/Authentication/AuthModal';
import Axeptio from '@/components/Axeptio/Axeptio';
import Loader from '@/components/Loading/Loader';
import resolutionsMixin from '@/mixins/resolutions';
import notificationMixin from '@/mixins/notification';
import { mapState } from 'pinia';
import { useMenuStore } from '@/stores/menu';
import { useGlobalStore } from '@/stores/global';

export default {
  name: 'DefaultLayout',
  components: {
    Reinsurance,
    Footer,
    Header,
    MenuMobile,
    AuthModal,
    Axeptio,
    Loader
  },
  mixins: [resolutionsMixin, notificationMixin],
  data: () => ({ menuShown: false, loading: false }),
  watch: {
    $route() {
      this.menuShown = false;
    }
  },
  computed: {
    ...mapState(useMenuStore, { menuEntries: 'entries' }),
    ...mapState(useGlobalStore, ['globalLoading'])
  },
  mounted() {
    setTimeout(() => this.$auth.loggedIn && this.$auth.fetchUser(), 100);
  },
  beforeDestroy() {},
  methods: {
    showGlobalLoader() {
      this.loading = true;
    },
    hideGlobalLoader() {
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.default-layout {
  display: flex;
  flex-direction: column;

  .body {
    position: relative;
    margin-top: $header-desktop-height;
    background-color: $porcelain;
    min-height: 100vh;

    @media (max-width: $md) {
      margin-top: $header-mobile-height;
    }
  }

  .global-loader {
    position: fixed;
    z-index: 99;
  }
}
</style>
