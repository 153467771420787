import Vue from 'vue';
import { capitalize, pascalCase, stripHtml } from '@/misc/string';
import { formatNumber, toCurrency } from '@/misc/currency';

const ROUND_TYPES = ['floor', 'ceil', 'none'];

export default ({ app }) => {
  Vue.filter('json', (value, replacer, space) =>
    JSON.stringify(value, replacer, space)
  );

  Vue.filter('capitalize', (value) => capitalize(value));

  Vue.filter(
    'toCurrency',
    (
      price,
      replacement = null,
      style = 'currency',
      minimumFractionDigits = 2,
      taxFree = false,
      displayTaxInformation = false
    ) => {
      if ((!price || price === 0) && replacement) {
        return replacement;
      }

      let value = toCurrency(price, style, minimumFractionDigits);
      if (taxFree) {
        value += ` ${app.i18n.t('common.tax-free')}`;
      } else if (displayTaxInformation) {
        value += ` ${app.i18n.t('common.tax-incl')}`;
      }

      return value;
    }
  );

  Vue.filter('formatNumber', (number) => {
    return formatNumber(number);
  });

  Vue.filter('pascalCase', (text) => {
    return pascalCase(text);
  });

  Vue.filter('round', (value) => Math.round(value));

  Vue.filter('stripHtml', stripHtml);

  Vue.filter('truncate', (text, stop) => {
    return text.slice(0, stop) + (stop < text.length ? '…' : '');
  });

  Vue.filter('reverse', (value) => value.slice().reverse());
};
