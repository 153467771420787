import Resolutions from '@/misc/resolutions';

export default {
  data() {
    return {
      resolutionsIsMounted: false
    };
  },
  computed: {
    ...Object.keys(Resolutions).reduce((output, key) => {
      output[key] = function () {
        return this.resolutionsIsMounted && Resolutions[key](this.$mq);
      };
      return output;
    }, {})
  },
  mounted() {
    this.resolutionsIsMounted = true;
  }
};
