import { doRequest } from '@/api/utils';

export async function getUser($axios, userId) {
  const { data } = await doRequest($axios, 'GET', `/users/${userId}`);
  return data;
}

export async function getUserBySlug($axios, slug) {
  const { data } = await doRequest($axios, 'GET', `/users/slug/${slug}`);
  return data;
}

export async function getPreferences($axios, userId) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/preferences`
  );
  return data;
}

export async function updateUser($axios, userId, postData) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/users/${userId}`,
    postData,
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function updateUserPreferences($axios, userId, postData) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/users/${userId}/preferences`,
    postData,
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function promoteUser($axios, userId) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/users/${userId}/promote`,
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function verifyAccount($axios, userId) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/users/${userId}/verify-account`,
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function getDocumentTypes($axios) {
  const { data } = await doRequest($axios, 'GET', `/user-document-types`);
  return data['hydra:member'] || null;
}

export async function uploadDocument($axios, postData) {
  const { data } = await doRequest(
    $axios,
    'POST',
    `/user-documents`,
    postData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  );
  return data['hydra:member'] || null;
}

export async function postIban($axios, postData) {
  const { data } = await doRequest($axios, 'POST', `/user-ibans`, postData);
  return data;
}

export async function getIban($axios, userId) {
  const { data } = await doRequest($axios, 'GET', `/users/${userId}/iban`);
  return data;
}

export async function transferToBankAccount($axios, userId) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/users/${userId}/bank-account-transfer`,
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function postReview($axios, postData) {
  const { data } = await doRequest($axios, 'POST', `/user-ratings`, postData);
  return data;
}

export async function patchReview($axios, ratingId, postData) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/user-ratings/${ratingId}`,
    postData,
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function getLikedItems(
  $axios,
  userId,
  limit = null,
  page = null,
  options = {}
) {
  const params = {};
  limit && (params.itemsPerPage = limit);
  page && (params.page = page);
  options.excludeSold && (params.excludeSold = '1');
  options.sortRandom && (params.sortRandom = '1');
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/favorite-items`,
    {},
    { params }
  );
  return data;
}

export async function likeItem($axios, itemIri) {
  const { data } = await doRequest($axios, 'POST', `/user-favorite-items`, {
    item: itemIri
  });
  return data;
}

export async function unlikeItem($axios, userFavoriteItemId) {
  const { data } = await doRequest(
    $axios,
    'DELETE',
    `/user-favorite-items/${userFavoriteItemId}`
  );
  return data;
}

export async function getAllFavoriteItemsIds($axios, userId) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/all-favorite-items`
  );
  return data['hydra:member'] || [];
}

export async function getFavoriteBrands(
  $axios,
  userId,
  limit = null,
  page = null
) {
  const params = {};
  limit && (params.itemsPerPage = limit);
  page && (params.page = page);
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/favorite-brands`,
    {},
    { params }
  );
  return data;
}

export async function addFavoriteBrand($axios, userId, brandIri) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/users/${userId}/add-favorite-brand`,
    { brand: brandIri },
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function removeFavoriteBrand($axios, userId, brandIri) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/users/${userId}/remove-favorite-brand`,
    { brand: brandIri },
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function getFavoriteColors($axios, userId) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/favorite-colors`
  );
  return data['hydra:member'] || [];
}

export async function addFavoriteColor($axios, userId, colorIri) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/users/${userId}/add-favorite-color`,
    { color: colorIri },
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function removeFavoriteColor($axios, userId, colorIri) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/users/${userId}/remove-favorite-color`,
    { color: colorIri },
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function getSellerDressing($axios, userId, page = 1) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/dressing?page=${page}`
  );
  return data;
}

export async function getPostalAddresses($axios, userId) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/postal-addresses`
  );
  return data['hydra:member'] || [];
}

export async function createPostalAddress($axios, postData) {
  const { data } = await doRequest(
    $axios,
    'POST',
    '/postal-addresses',
    postData
  );
  return data;
}

export async function patchPostalAddress($axios, postalAddressId, postData) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/postal-addresses/${postalAddressId}`,
    postData,
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function deletePostalAddress($axios, postalAddressId) {
  const { data } = await doRequest(
    $axios,
    'DELETE',
    `/postal-addresses/${postalAddressId}`
  );
  return data;
}

export async function getCantons($axios) {
  const { data } = await doRequest($axios, 'GET', `/cantons`);
  return data['hydra:member'] || [];
}

export async function getOffers($axios, userId) {
  const { data } = await doRequest($axios, 'GET', `/users/${userId}/offers`);
  return data;
}

export async function getReceivedOffers($axios, userId) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/received-offers`
  );
  return data;
}

export async function getBlockedUsers($axios, userId) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/blocked-users`
  );
  return data;
}

export async function addBlockedUser($axios, userId, postData) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/users/${userId}/add-blocked-user`,
    postData,
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function removeBlockedUser($axios, userId, postData) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/users/${userId}/remove-blocked-user`,
    postData,
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function postUserReport($axios, postData) {
  const { status } = await doRequest($axios, 'POST', '/user-reports', postData);
  return status === 201;
}

export async function getUserReportReasons($axios, page = null) {
  const params = {};
  if (page) {
    params.page = page;
  }
  const { data } = await doRequest(
    $axios,
    'GET',
    '/report-reasons',
    {},
    { params }
  );
  return page ? data : data['hydra:member'] || null;
}

export async function getFollowedUser($axios, userId) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/followed-users`
  );
  return data;
}

export async function addFollowedUser($axios, userId, postData) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/users/${userId}/add-followed-user`,
    postData,
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function removeFollowedUser($axios, userId, postData) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/users/${userId}/remove-followed-user`,
    postData,
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function getDressing($axios, userId) {
  const { data } = await doRequest($axios, 'GET', `/users/${userId}/dressing`);
  return data;
}

export async function getElasticDressing($axios, slug, params) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${slug}/items`,
    {},
    { params }
  );
  return data;
}

export async function getElasticDressingAggs($axios, slug, params) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${slug}/aggs`,
    {},
    { params }
  );
  return data;
}

export async function getUserRatings($axios, userId) {
  const { data } = await doRequest($axios, 'GET', `/users/${userId}/ratings`);
  return data;
}

export async function postRatingResponse($axios, ratingId, postData) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/user-ratings/${ratingId}/respond`,
    postData,
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function translateReview($axios, ratingId) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/user-ratings/${ratingId}/translate`,
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function deleteRating($axios, ratingId) {
  const { data } = await doRequest(
    $axios,
    'DELETE',
    `/user-ratings/${ratingId}`
  );
  return data;
}

export async function deleteRatingResponse($axios, ratingId) {
  const { data } = await doRequest(
    $axios,
    'DELETE',
    `/user-ratings/${ratingId}/response`
  );
  return data;
}

export async function getPurchases($axios, userId, page = null, period = null) {
  const params = {};
  page && (params.page = page);
  period && (params.period = period);
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/purchases`,
    {},
    { params }
  );
  return data;
}

export async function getSales($axios, userId, page = null, period = null) {
  const params = {};
  page && (params.page = page);
  period && (params.period = period);
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/sales`,
    {},
    { params }
  );
  return data;
}

export async function consultItem($axios, itemIri) {
  const { data } = await doRequest($axios, 'POST', '/item-consultations', {
    item: itemIri
  });
  return data;
}

export async function getRecentConsultedItems(
  $axios,
  userId,
  limit = null,
  page = null
) {
  const params = {};
  limit && (params.itemsPerPage = limit);
  page && (params.page = page);
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/recent-consultations`,
    {},
    { params }
  );
  return data;
}

export async function deleteCreditCard($axios, cardId) {
  const { data } = await doRequest($axios, 'DELETE', `/credit-cards/${cardId}`);
  return data;
}

export async function deleteAccount($axios, userId, postData) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/users/${userId}/delete`,
    postData,
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function getNotifications($axios, userId) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/notifications`
  );
  return data;
}

export async function readNotification($axios, notificationId) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/notifications/${notificationId}/read`,
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function archiveNotification($axios, notificationId) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/notifications/${notificationId}/read`,
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function getSearches($axios, userId) {
  const { data } = await doRequest($axios, 'GET', `/users/${userId}/searches`);
  return data;
}

export async function getSearchHistories($axios, userId, page = null) {
  const params = {};
  page && (params.page = page);
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/search-histories`,
    {},
    { params }
  );
  return data;
}

export async function saveSearch($axios, payload, notify) {
  const { data } = await doRequest($axios, 'POST', '/searches', {
    payload,
    notify
  });
  return data;
}

export async function removeSearch($axios, searchId) {
  const { data } = await doRequest($axios, 'DELETE', `/searches/${searchId}`);
  return data;
}

export async function obtainPhoneNumberCode($axios, userId) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/users/${userId}/obtain-phone-number-code`,
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function verifyPhoneNumber($axios, userId, code) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/users/${userId}/verify-phone-number-code`,
    { code },
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function getTransferHistory($axios, userId) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/transfer-history`
  );
  return data['hydra:member'] || null;
}

export async function getTaxonCustomizations($axios, userId) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/taxon-customizations`
  );
  return data;
}

export async function getTaxonCustomization($axios, taxonCustomizationId) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/taxon-customizations/${taxonCustomizationId}`
  );
  return data;
}

export async function patchTaxonCustomizations(
  $axios,
  taxonCustomizationId,
  postData
) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/taxon-customizations/${taxonCustomizationId}`,
    postData,
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function getLastSearchItems($axios, userId) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/last-search-items`
  );
  return data['hydra:member'] || [];
}

export async function getForYouItems($axios, userId, page = 1, perPage = null) {
  const params = {};
  page && (params.page = page);
  perPage && (params.itemsPerPage = perPage);
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/for-you`,
    {},
    { params }
  );
  return data;
}

export async function getBrandsForYouItems($axios, userId, perPage = null) {
  const params = {};
  perPage && (params.itemsPerPage = perPage);
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/brands-for-you`,
    {},
    { params }
  );
  return data['hydra:member'] || [];
}

export async function getBrandForYouItems(
  $axios,
  userId,
  brandId,
  page = 1,
  perPage = null
) {
  const params = {};
  page && (params.page = page);
  perPage && (params.itemsPerPage = perPage);
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/brand/${brandId}/for-you`,
    {},
    { params }
  );
  return data;
}

export async function getPopularForYouItems(
  $axios,
  userId,
  page = 1,
  perPage = null,
  randomize = false
) {
  const params = {};
  page && (params.page = page);
  perPage && (params.itemsPerPage = perPage);
  randomize && (params.randomize = 1);
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/popular-for-you`,
    {},
    { params }
  );
  return data;
}

export async function getNewsfeedItems(
  $axios,
  userId,
  page = 1,
  perPage = null
) {
  const params = {};
  page && (params.page = page);
  perPage && (params.itemsPerPage = perPage);
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/newsfeed`,
    {},
    { params }
  );
  return data;
}

export async function google2FAActivate($axios, userId) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/users/${userId}/google-2fa-activate`,
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function google2FAVerify($axios, userId, code) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/users/${userId}/google-2fa-verify`,
    { code },
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function google2FADisable($axios, userId) {
  const { data } = await doRequest(
    $axios,
    'DELETE',
    `/users/${userId}/google-2fa`
  );
  return data;
}

export async function email2FAActivate($axios, userId) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/users/${userId}/email-2fa-activate`,
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function email2FAVerify($axios, userId, code) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/users/${userId}/email-2fa-verify`,
    { code },
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function email2FADisable($axios, userId) {
  const { data } = await doRequest(
    $axios,
    'DELETE',
    `/users/${userId}/email-2fa`
  );
  return data;
}

export async function userSearch($axios, partial) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/search?username=${partial}`
  );
  return data?.['hydra:member'] || [];
}

export async function updatePartner($axios, name) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/partners`,
    { name },
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}
