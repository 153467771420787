import isNil from 'lodash.isnil';

export default function (context) {
  const { $auth, app, route } = context;
  const { router } = app;
  if (isNil(route.name)) return;
  const found = route.name.match(/Account-UserSettings___(?:fr|en|de|it)/);
  if ($auth?.user?.banned && isNil(found)) {
    router.push(
      app.localePath({
        name: 'Account-UserSettings',
        query: { tab: 'wallet' }
      })
    );
  }
}
