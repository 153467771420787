<template>
  <div class="error">
    <h1 v-if="error.statusCode === 404">
      {{ $t('error-page.not-found-heading') }}
    </h1>
    <h1 v-else>{{ $t('error-page.heading') }}</h1>
    <p v-if="error" class="error__message">{{ error.message }}</p>
    <pre style="font-size: 12px; word-break: break-word; white-space: normal">
      {{ error }}
    </pre>
    <div class="error__buttons">
      <Button outline :to="localePath('/')">{{
        $t('error-page.back-home')
      }}</Button>
      <Button outline @click="onReload">{{
        $t('error-page.reload-page')
      }}</Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button/Button';

export default {
  components: { Button },
  layout: 'default',
  props: {
    error: {
      type: Object,
      required: true
    }
  },
  methods: {
    onReload() {
      process.client && window.location.reload();
    }
  }
};
</script>

<style lang="scss" scoped>
.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  font-size: 18px;

  &__message {
    color: $gray;
    font-size: 15px;
    font-style: italic;
  }

  &__buttons {
    display: flex;
    gap: 10px;
    margin-top: 30px;
  }
}
</style>
