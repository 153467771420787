<template>
  <div class="search">
    <Input
      v-model="query"
      class="search__input"
      :placeholder="$t('header.search-articles')"
      @input="onQueryInput"
      @keydown.enter="onQuerySubmited"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />
    <CrossSvg v-if="query" class="search__cross" @click="clearSearchTerms" />
    <div class="search__icon" @click="onQuerySubmited">
      <img src="./img/search.svg" alt="Search icon" />
    </div>
  </div>
</template>

<script>
import Input from '@/components/Input/Input';
import isNil from 'lodash.isnil';
import CrossSvg from '~/assets/img/cross.svg?inline';
import { mapState, mapActions } from 'pinia';
import { useGlobalStore } from '@/stores/global';

export default {
  name: 'Search',
  components: {
    Input,
    CrossSvg
  },
  computed: {
    ...mapState(useGlobalStore, ['searchTerms']),
    query: {
      get() {
        return this.searchTerms;
      },
      set(val) {
        this.setSearchTerms(val);
      }
    }
  },
  methods: {
    ...mapActions(useGlobalStore, ['setSearchTerms', 'clearSearchTerms']),
    onQueryInput() {
      // TODO Autocomplete ?
    },
    onQuerySubmited(event) {
      const { query } = this;
      if (isNil(query) || query.length < 3) return;
      event.target.blur();
      this.$router.push(
        this.localePath({
          name: 'ListPage-Search',
          query: { q: query }
        })
      );
      this.$emit('submitted');
    }
  }
};
</script>

<style lang="scss" scoped src="./search.scss"></style>
