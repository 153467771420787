import { mapActions } from 'pinia';
import { useInboxStore } from '@/stores/inbox';
import { useUserStore } from '@/stores/user';

export default {
  mounted() {
    this.reloadAll();
    this.$bus.$on('sign-in:success', this.reloadAll);
  },
  beforeDestroy() {
    this.$bus.$off('mercure:message', this.notificationUpdateHandler);
    this.$bus.$off('sign-in:success', this.reloadAll);
  },
  methods: {
    ...mapActions(useUserStore, ['fetchNotifications']),
    ...mapActions(useInboxStore, ['loadConversations']),
    async reloadAll() {
      if (this.$auth.loggedIn) {
        await Promise.all([
          this.$auth.fetchUser(),
          this.fetchNotifications().catch(() => null)
        ]);
        this.loadConversations(this.$auth.user.id, { onlyIfNull: true }).catch(
          () => null
        );
        this.listenToMercureMessages();
      }
    },
    listenToMercureMessages() {
      if (process.client) {
        this.$bus.$on('mercure:message', this.notificationUpdateHandler);
      }
    },
    async reloadNotifications() {
      await this.fetchNotifications().catch(() => null);
    },
    async notificationUpdateHandler(data) {
      switch (data['@type']) {
        case 'Conversation':
          await this.loadConversations(this.$auth.user.id).catch(() => null);
          break;
        case 'Notification':
          await this.reloadNotifications();
          break;
      }
    }
  }
};
