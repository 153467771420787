import upperFirst from 'lodash.upperfirst';
import camelCase from 'lodash.camelcase';

export const capitalize = (text) => {
  if (typeof text !== 'string') return '';
  return upperFirst(text);
};

export const pascalCase = (text) => {
  if (!text || text.length < 1) {
    return text;
  }
  return upperFirst(camelCase(text));
};

export const sanitize = (text) => {
  if (typeof text !== 'string') {
    return text;
  }
  return text.replace(/(<([^>]+)>)/gi, '').trim();
};

export const base64EncodeUnicode = (str) => {
  if (process.client) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(
      encodeURIComponent(str).replace(
        /%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1);
        }
      )
    );
  } else {
    return Buffer.from(str).toString('base64');
  }
};

export const base64DecodeUnicode = (str) => {
  if (process.client) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  } else {
    return Buffer.from(str, 'base64').toString();
  }
};

export const stripHtml = (html) => {
  return html ? html.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '') : '';
};

export const numberFormat = (number, digits = 2, locale = undefined) => {
  const options = { maximumFractionDigits: digits };
  return Intl.NumberFormat(locale, options).format(number);
};

export const extractUuid = (str) => {
  const regex = new RegExp(/(?:\w{4,12}-?){5}/);
  const matches = regex.exec(str);
  return matches?.[0];
};

export const nl2br = (str, is_xhtml) => {
  const breakTag =
    is_xhtml || typeof is_xhtml === 'undefined' ? '<br ' + '/>' : '<br>';

  return (str + '').replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    '$1' + breakTag + '$2'
  );
};
