<template>
  <input :type="type" v-bind="$attrs" class="input" v-on="inputListeners" />
</template>

<script>
export default {
  name: 'Input',
  props: {
    type: {
      type: String,
      default: 'text'
    }
  },
  computed: {
    inputListeners() {
      const vm = this;
      return Object.assign({}, this.$listeners, {
        input: (event) => vm.$emit('input', event.target.value, event),
        keydown: (event) => vm.$emit('keydown', event),
        keyup: (event) => vm.$emit('keyup', event),
        focus: (event) => vm.$emit('focus', event),
        blur: (event) => vm.$emit('blur', event)
      });
    }
  }
};
</script>

<style lang="scss" scoped src="./input.scss"></style>
