<template>
  <div class="header">
    <div class="container">
      <div class="header__top">
        <div class="header__top__burger test" @click="onBurgerClick">
          <BurgerSvg />
        </div>
        <nuxt-link
          v-slot="{ href, navigate }"
          :to="localePath('index')"
          custom
          class="header__top__logo"
        >
          <a
            :href="href"
            @click="onLogoNavigate(navigate, $event)"
            @keypress.enter="onLogoNavigate(navigate, $event)"
          >
            <img src="~/assets/img/clozen.svg" alt="Logo Clozen" />
          </a>
        </nuxt-link>
        <div class="header__top__search">
          <Search
            v-click-outside="hideSavedSearch"
            @focus="savedSearchShown = true"
            @submitted="savedSearchShown = false"
          />
          <div class="header__top__search__buttons">
            <client-only placeholder="">
              <Button
                v-if="!$auth.loggedIn"
                class="header__top__search__login-button"
                outline
                @click="$bus.$emit('auth:login:show')"
              >
                {{ $t('header.signin-signup') }}
              </Button>
            </client-only>
          </div>
          <transition name="fade">
            <SavedSearch
              v-if="saveSearchEnabled && $auth.loggedIn"
              v-show="savedSearchShown"
              class="header__top__search__saved-search"
              @search:click="onSearchClick"
            />
          </transition>
        </div>
        <div class="header__top__icons">
          <!-- Messages -->
          <nuxt-link
            v-if="$auth.loggedIn"
            :to="localePath('Account-Inbox')"
            class="header__top__icons__icon"
          >
            <LetterSvg width="22" height="22" />
            <client-only placeholder="">
              <span
                v-if="unreadConversationCount"
                class="header__top__icons__icon__badge"
              >
                {{ unreadConversationCount }}
              </span>
            </client-only>
          </nuxt-link>
          <!-- Notifications -->
          <tippy
            v-if="$auth.loggedIn"
            ref="notificationTippy"
            interactive
            placement="bottom"
            arrow
            :trigger="
              notifications && notifications.length ? 'click' : 'manual'
            "
            theme="light"
            @shown="notificationTippyOpened = true"
            @hidden="readNotifications"
          >
            <template #trigger>
              <div class="header__top__icons__icon">
                <NotificationBellSvg />
                <span
                  v-if="unreadNotificationCount"
                  class="header__top__icons__icon__badge"
                >
                  {{ unreadNotificationCount }}
                </span>
              </div>
            </template>
            <div
              v-show="isMounted && notifications && notifications.length"
              v-scroll-lock="notificationTippyOpened"
              class="header__top__notifications-dropdown"
            >
              <vue-scroll>
                <NotificationDropdown
                  :notifications="notifications"
                  @notification:redirect="onNotificationRedirect"
                />
              </vue-scroll>
            </div>
          </tippy>
          <!-- Favorites -->
          <nuxt-link
            v-if="$auth.loggedIn"
            :to="localePath('ListPage-UserFavorites')"
            class="header__top__icons__icon"
          >
            <HeartSvg width="22" height="22" />
          </nuxt-link>
          <!-- User -->
          <tippy
            v-if="$auth.loggedIn"
            ref="userTippy"
            interactive
            placement="bottom"
            arrow
            trigger="click"
            theme="light"
            class="header__top__icons__icon header__top__icons__icon__user__wrapper"
          >
            <template #trigger>
              <div class="header__top__icons__icon__user">
                <img
                  v-if="$auth.user.avatar"
                  :src="mediaSrc($auth.user.avatar, 'header')"
                  :srcset="mediaSrcset($auth.user.avatar, 'header')"
                  alt=""
                />
                <img v-else src="~/assets/img/user-sm.png" alt="" />
                <DownSvg width="10" height="10" />
              </div>
            </template>
            <div v-show="isMounted" class="header__top__user-dropdown">
              <nuxt-link
                :to="
                  localePath({
                    name: 'Member',
                    params: { slug: $auth.user.slug }
                  })
                "
                class="header__top__user-dropdown__entry"
                @click.native="hideUserDropdown"
              >
                {{ $t('header.user-dropdown.dressing') }}
              </nuxt-link>
              <!-- <nuxt-link
                :to="
                  localePath({
                    name: 'Account-UserSettings',
                    query: { tab: 'orders' }
                  })
                "
                class="header__top__user-dropdown__entry"
                @click.native="hideUserDropdown"
              >
                {{ $t('header.user-dropdown.purchases') }}
              </nuxt-link> -->
              <!-- <nuxt-link
                :to="
                  localePath({
                    name: 'Account-UserSettings',
                    query: { tab: 'sales' }
                  })
                "
                class="header__top__user-dropdown__entry"
                @click.native="hideUserDropdown"
              >
                {{ $t('header.user-dropdown.sales') }}
              </nuxt-link> -->
              <!-- <nuxt-link
                :to="
                  localePath({
                    name: 'Account-UserSettings',
                    query: { tab: 'wallet' }
                  })
                "
                class="header__top__user-dropdown__entry"
                @click.native="hideUserDropdown"
                v-html="
                  $t('header.user-dropdown.wallet', {
                    currency: $options.filters.toCurrency(balance)
                  })
                "
              /> -->
              <nuxt-link
                v-if="searchHistoryEnabled"
                :to="localePath({ name: 'Account-SearchHistory' })"
                class="header__top__user-dropdown__entry"
                @click.native="hideUserDropdown"
                v-html="$t('header.user-dropdown.search-history')"
              />
              <!-- <nuxt-link
                :to="
                  localePath({
                    name: 'Account-UserSettings',
                    query: { tab: 'customization' }
                  })
                "
                class="header__top__user-dropdown__entry"
                @click.native="hideUserDropdown"
              >
                {{ $t('header.user-dropdown.cutomizing') }}
              </nuxt-link> -->
              <nuxt-link
                :to="
                  localePath({
                    name: 'Account-UserSettings',
                    query: { tab: 'settings' }
                  })
                "
                class="header__top__user-dropdown__entry"
                @click.native="hideUserDropdown"
              >
                {{ $t('header.user-dropdown.settings') }}
              </nuxt-link>
              <div
                class="header__top__user-dropdown__entry text-red"
                @click="onLogout"
              >
                {{ $t('header.user-dropdown.logout') }}
              </div>
            </div>
          </tippy>
          <!-- Sell now -->
          <Button
            :to="localePath({ name: 'AddProduct' })"
            class="header__top__sell-button"
          >
            {{ $t('common.sell-now') }}
          </Button>
          <!-- Support -->
          <!-- <a
            :href="$t('common.support.home')"
            target="_blank"
            rel="noreferer noopener"
            class="header__top__icons__icon"
          >
            <QuestionSvg />
          </a> -->
          <!-- Language -->
          <tippy
            ref="localeTippy"
            interactive
            placement="bottom"
            arrow
            trigger="click"
            theme="light"
            class="header__top__icons__icon"
          >
            <template #trigger>
              <div class="header__top__icons__icon__locale">
                {{ currentLocale.name }}
                <DownSvg width="10" height="10" />
              </div>
            </template>
            <div v-show="isMounted" class="header__top__user-dropdown">
              <div
                v-for="loc in $i18n.locales"
                :key="loc.code"
                class="header__top__user-dropdown__entry"
                @click="switchLocale(loc)"
              >
                {{ loc.fullname }}
              </div>
            </div>
          </tippy>
        </div>
      </div>
      <div class="header__menu" :class="{ 'header__menu--open': showMenu }">
        <Menu v-if="!isMdMinus" :entries="menuEntries" :show="showMenu" />
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import Button from '@/components/Button/Button';
import Search from './components/Search/Search';
import SavedSearch from './components/SavedSearch/SavedSearch';
import NotificationBellSvg from './img/notification-bell.svg?inline';
import LetterSvg from '~/assets/img/letter.svg?inline';
import DownSvg from '~/assets/img/down.svg?inline';
import QuestionSvg from './img/question.svg?inline';
import HeartSvg from './img/heart.svg?inline';
import BurgerSvg from './img/burger.svg?inline';
import Menu from './components/Menu/Menu';
import NotificationDropdown from './components/NotificationDropdown/NotificationDropdown';
import { updateUserPreferences, readNotification } from '@/api/user';
import { mapActions, mapState } from 'pinia';
import { useInboxStore } from '@/stores/inbox';
import { useMenuStore } from '@/stores/menu';
import { useUserStore } from '@/stores/user';
import { useGlobalStore } from '@/stores/global';
import resolutionsMixin from '@/mixins/resolutions';
import { captureErrorMixin } from '@/mixins/captureError';
import mediaObject from '@/mixins/mediaObject';
import isNil from 'lodash.isnil';
import isEmpty from 'lodash.isempty';
import { sleep } from '@/misc/utils';

export default {
  name: 'Header',
  components: {
    Button,
    Search,
    SavedSearch,
    NotificationBellSvg,
    LetterSvg,
    DownSvg,
    QuestionSvg,
    HeartSvg,
    BurgerSvg,
    Menu,
    NotificationDropdown
  },
  directives: { ClickOutside },
  mixins: [resolutionsMixin, captureErrorMixin, mediaObject],
  data: () => ({
    isMounted: false,
    showMenu: false,
    saveSearchEnabled: false,
    searchHistoryEnabled: false,
    savedSearchShown: false,
    mobileMenuOpened: false,
    notificationTippyOpened: false,
    notificationEventSource: null
  }),
  computed: {
    ...mapState(useMenuStore, { menuEntries: 'entries' }),
    ...mapState(useUserStore, ['notifications', 'unreadNotifications']),
    // ...mapState(useUserStore, { unreadNotifications: 'notifications' }),
    ...mapState(useInboxStore, ['unreadConversations']),
    currentLocale() {
      return this.$i18n.locales.find((l) => l.code === this.$i18n.locale);
    },
    balance() {
      return this.$auth.user?.balance || 0;
    },
    unreadConversationCount() {
      if (isNil(this.unreadConversations)) return 0;
      return this.unreadConversations(this.$auth.user.id).length;
    },
    unreadNotificationCount() {
      if (isNil(this.unreadNotifications)) return 0;
      return this.unreadNotifications.length;
    }
  },
  async created() {
    if (this.$auth.loggedIn) {
      this.saveSearchEnabled = await this.$feature.isActive('save-search');
      this.searchHistoryEnabled = await this.$feature.isActive(
        'search-history'
      );
    }
  },
  mounted() {
    this.isMounted = true;
    this.$bus.$on('menu:show', () => (this.showMenu = true));
    this.$bus.$on('menu:hide', () => (this.showMenu = false));
  },
  beforeDestroy() {
    this.$bus.$off('menu:show', () => (this.showMenu = true));
    this.$bus.$off('menu:hide', () => (this.showMenu = false));
  },
  watch: {
    $route() {
      this.mobileMenuOpened = false;
    }
  },
  methods: {
    ...mapActions(useGlobalStore, ['setGlobalLoading']),
    hideUserDropdown() {
      this.$refs.userTippy?.tip?.hide();
    },
    hideSavedSearch(event) {
      if (!isNil(event)) {
        const { target } = event;
        const hasParent = !isNil(
          target.closest('.header__top__search__saved-search')
        );
        if (hasParent) return;
      }
      this.savedSearchShown = false;
    },
    async onSearchClick(search) {
      const filters = search.payload;
      const params = {};
      if (!isEmpty(filters.query)) {
        params.q = filters.query;
        delete filters.query;
      }
      params.filters = { ...filters };
      const url = this.localePath({
        name: 'ListPage-Search',
        query: params
      });
      this.hideSavedSearch();
      await this.$router.push(url);
    },
    async onLogout() {
      this.hideUserDropdown();
      await this.$auth.logout();
    },
    onToggleMenu() {
      this.$bus.$emit('mobile-menu:toggle');
    },
    async switchLocale(locale) {
      this.setGlobalLoading(true);
      const { code } = locale;
      !isNil(code) && this.$i18n.setLocale(code);
      this.$refs.localeTippy?.tip?.hide();
      if (this.$auth.loggedIn) {
        await this.try(async () => {
          await this.$api.doRequest(updateUserPreferences, this.$auth.user.id, {
            locale: code
          });
          await this.$auth.fetchUser();
        });
      } else {
        await sleep(150);
        window.location.reload();
      }
      // Give the time to page to reload
      await sleep(10000);
      this.setGlobalLoading(false);
    },
    onBurgerClick() {
      this.$emit('menu:toggle');
      this.mobileMenuOpened = !this.mobileMenuOpened;
    },
    onLogoNavigate(navigate, event) {
      if (!this.mobileMenuOpened) navigate(event);
      else {
        event.preventDefault();
        this.onBurgerClick();
      }
    },
    async readNotifications() {
      if (isEmpty(this.unreadNotifications)) return;
      this.notificationTippyOpened = false;
      await Promise.all(
        this.unreadNotifications.map((n) =>
          this.try(async () => {
            await this.$api.doRequest(readNotification, n.id);
          })
        )
      );
      this.$emit('notifications:reload');
    },
    onNotificationRedirect() {
      this.$refs.notificationTippy?.tip?.hide();
    }
  }
};
</script>

<style lang="scss" scoped src="./header.scss"></style>
