import locale275327d8 from '../../locales/fr.json'
import locale9ec66352 from '../../locales/en.json'
import locale6c5af57f from '../../locales/de.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"fr"},
  vueI18nLoader: false,
  locales: [{"code":"fr","file":"fr.json","iso":"fr-CH","name":"FR","fullname":"Français"},{"code":"en","file":"en.json","iso":"en-US","name":"EN","fullname":"English"},{"code":"de","file":"de.json","iso":"de-DE","name":"DE","fullname":"Deutsch"}],
  defaultLocale: "fr",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: false,
  langDir: "locales/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "https://clozen.ch",
  vuex: false,
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  cookieKey: "i18n_redirected",
  normalizedLocales: [{"code":"fr","file":"fr.json","iso":"fr-CH","name":"FR","fullname":"Français"},{"code":"en","file":"en.json","iso":"en-US","name":"EN","fullname":"English"},{"code":"de","file":"de.json","iso":"de-DE","name":"DE","fullname":"Deutsch"}],
  localeCodes: ["fr","en","de"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "f",
  3: "r",
  4: ".",
  5: "j",
  6: "s",
  7: "o",
  8: "n",
  9: "\"",
  10: ":",
  11: "\"",
  12: ".",
  13: ".",
  14: "/",
  15: "l",
  16: "o",
  17: "c",
  18: "a",
  19: "l",
  20: "e",
  21: "s",
  22: "/",
  23: "f",
  24: "r",
  25: ".",
  26: "j",
  27: "s",
  28: "o",
  29: "n",
  30: "\"",
  31: ",",
  32: "\"",
  33: "e",
  34: "n",
  35: ".",
  36: "j",
  37: "s",
  38: "o",
  39: "n",
  40: "\"",
  41: ":",
  42: "\"",
  43: ".",
  44: ".",
  45: "/",
  46: "l",
  47: "o",
  48: "c",
  49: "a",
  50: "l",
  51: "e",
  52: "s",
  53: "/",
  54: "e",
  55: "n",
  56: ".",
  57: "j",
  58: "s",
  59: "o",
  60: "n",
  61: "\"",
  62: ",",
  63: "\"",
  64: "d",
  65: "e",
  66: ".",
  67: "j",
  68: "s",
  69: "o",
  70: "n",
  71: "\"",
  72: ":",
  73: "\"",
  74: ".",
  75: ".",
  76: "/",
  77: "l",
  78: "o",
  79: "c",
  80: "a",
  81: "l",
  82: "e",
  83: "s",
  84: "/",
  85: "d",
  86: "e",
  87: ".",
  88: "j",
  89: "s",
  90: "o",
  91: "n",
  92: "\"",
  93: "}",
}

export const localeMessages = {
  'fr.json': () => Promise.resolve(locale275327d8),
  'en.json': () => Promise.resolve(locale9ec66352),
  'de.json': () => Promise.resolve(locale6c5af57f),
}
