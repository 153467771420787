import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f61acc70 = () => interopDefault(import('../pages/Welcome/index.vue' /* webpackChunkName: "pages/Welcome/index" */))
const _2a2925bf = () => interopDefault(import('../pages/Catalog/index.vue' /* webpackChunkName: "pages/Catalog/index" */))
const _c9332952 = () => interopDefault(import('../pages/HowItWorks/index.vue' /* webpackChunkName: "pages/HowItWorks/index" */))
const _4be25418 = () => interopDefault(import('../pages/TermsAndConditions/index.vue' /* webpackChunkName: "pages/TermsAndConditions/index" */))
const _2a433156 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5e1d71dd = () => interopDefault(import('../pages/Impressum/index.vue' /* webpackChunkName: "pages/Impressum/index" */))
const _6f078a94 = () => interopDefault(import('../pages/Auth/Logout.vue' /* webpackChunkName: "pages/Auth/Logout" */))
const _5ab63300 = () => interopDefault(import('../pages/PrivacyPolicy/index.vue' /* webpackChunkName: "pages/PrivacyPolicy/index" */))
const _13db8a6d = () => interopDefault(import('../pages/ListPage/Search.vue' /* webpackChunkName: "pages/ListPage/Search" */))
const _a8e3db20 = () => interopDefault(import('../pages/Account/SearchHistory/index.vue' /* webpackChunkName: "pages/Account/SearchHistory/index" */))
const _0a6ec3aa = () => interopDefault(import('../pages/Account/Inbox/index.vue' /* webpackChunkName: "pages/Account/Inbox/index" */))
const _4defb900 = () => interopDefault(import('../pages/Account/UserSettings/index.vue' /* webpackChunkName: "pages/Account/UserSettings/index" */))
const _33376cf6 = () => interopDefault(import('../pages/Auth/PasswordResetRequest.vue' /* webpackChunkName: "pages/Auth/PasswordResetRequest" */))
const _231f04cc = () => interopDefault(import('../pages/Auth/SignIn.vue' /* webpackChunkName: "pages/Auth/SignIn" */))
const _37b55b42 = () => interopDefault(import('../pages/Auth/SignUp.vue' /* webpackChunkName: "pages/Auth/SignUp" */))
const _59d7dc1a = () => interopDefault(import('../pages/Auth/SocialRedirect.vue' /* webpackChunkName: "pages/Auth/SocialRedirect" */))
const _1a64abcf = () => interopDefault(import('../pages/Checkout/Cart/index.vue' /* webpackChunkName: "pages/Checkout/Cart/index" */))
const _36fb93e4 = () => interopDefault(import('../pages/Checkout/Cart/old.vue' /* webpackChunkName: "pages/Checkout/Cart/old" */))
const _d8bc750a = () => interopDefault(import('../pages/ListPage/RecoPopularForYou.vue' /* webpackChunkName: "pages/ListPage/RecoPopularForYou" */))
const _805f1d42 = () => interopDefault(import('../pages/ListPage/RecentlyViewedItems.vue' /* webpackChunkName: "pages/ListPage/RecentlyViewedItems" */))
const _b0d7c49c = () => interopDefault(import('../pages/ListPage/LatestPublished.vue' /* webpackChunkName: "pages/ListPage/LatestPublished" */))
const _67b14d15 = () => interopDefault(import('../pages/ListPage/RecoNewsFeed.vue' /* webpackChunkName: "pages/ListPage/RecoNewsFeed" */))
const _c2304944 = () => interopDefault(import('../pages/ListPage/Highlighted.vue' /* webpackChunkName: "pages/ListPage/Highlighted" */))
const _70fcbc97 = () => interopDefault(import('../pages/ListPage/UserFavorites.vue' /* webpackChunkName: "pages/ListPage/UserFavorites" */))
const _a43d0dcc = () => interopDefault(import('../pages/ListPage/RecoForYou.vue' /* webpackChunkName: "pages/ListPage/RecoForYou" */))
const _1b8652ce = () => interopDefault(import('../pages/Member/components/Dressing/Dressing.vue' /* webpackChunkName: "pages/Member/components/Dressing/Dressing" */))
const _605c68c8 = () => interopDefault(import('../pages/Member/components/Ratings/Ratings.vue' /* webpackChunkName: "pages/Member/components/Ratings/Ratings" */))
const _3c8ec9ce = () => interopDefault(import('../pages/Member/components/UserReport/UserReport.vue' /* webpackChunkName: "pages/Member/components/UserReport/UserReport" */))
const _bc14fe90 = () => interopDefault(import('../pages/Account/UserSettings/components/Bank/Bank.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Bank/Bank" */))
const _233a6c46 = () => interopDefault(import('../pages/Account/UserSettings/components/BrandList/Brand.vue' /* webpackChunkName: "pages/Account/UserSettings/components/BrandList/Brand" */))
const _66317b04 = () => interopDefault(import('../pages/Account/UserSettings/components/BrandList/BrandList.vue' /* webpackChunkName: "pages/Account/UserSettings/components/BrandList/BrandList" */))
const _0939df98 = () => interopDefault(import('../pages/Account/UserSettings/components/BundleDiscount/BundleDiscount.vue' /* webpackChunkName: "pages/Account/UserSettings/components/BundleDiscount/BundleDiscount" */))
const _fd7ca9a0 = () => interopDefault(import('../pages/Account/UserSettings/components/Customization/Color.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Customization/Color" */))
const _3e2032c0 = () => interopDefault(import('../pages/Account/UserSettings/components/Customization/Customization.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Customization/Customization" */))
const _4f00b918 = () => interopDefault(import('../pages/Account/UserSettings/components/Holidays/Holidays.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Holidays/Holidays" */))
const _53e2e2d0 = () => interopDefault(import('../pages/Account/UserSettings/components/Menu/Menu.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Menu/Menu" */))
const _2c5a1fa6 = () => interopDefault(import('../pages/Account/UserSettings/components/MyAddresses/MyAddresses.vue' /* webpackChunkName: "pages/Account/UserSettings/components/MyAddresses/MyAddresses" */))
const _70390786 = () => interopDefault(import('../pages/Account/UserSettings/components/MyPurchases/MyPurchases.vue' /* webpackChunkName: "pages/Account/UserSettings/components/MyPurchases/MyPurchases" */))
const _90be320c = () => interopDefault(import('../pages/Account/UserSettings/components/MySales/MySales.vue' /* webpackChunkName: "pages/Account/UserSettings/components/MySales/MySales" */))
const _0ed13fd0 = () => interopDefault(import('../pages/Account/UserSettings/components/Notification/Notification.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Notification/Notification" */))
const _ff183bd0 = () => interopDefault(import('../pages/Account/UserSettings/components/Settings/Settings.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Settings/Settings" */))
const _0340d250 = () => interopDefault(import('../pages/Account/UserSettings/components/TaxonCustomization/TaxonCustomization.vue' /* webpackChunkName: "pages/Account/UserSettings/components/TaxonCustomization/TaxonCustomization" */))
const _7cf8e8d8 = () => interopDefault(import('../pages/Account/UserSettings/components/Wallet/Wallet.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Wallet/Wallet" */))
const _0ad2d215 = () => interopDefault(import('../pages/Checkout/Cart/components/PaymentSelector/PaymentSelector.vue' /* webpackChunkName: "pages/Checkout/Cart/components/PaymentSelector/PaymentSelector" */))
const _c08323e0 = () => interopDefault(import('../pages/Account/UserSettings/components/MyAddresses/components/PostalAddress.vue' /* webpackChunkName: "pages/Account/UserSettings/components/MyAddresses/components/PostalAddress" */))
const _5f5e14f5 = () => interopDefault(import('../pages/Account/UserSettings/components/Settings/components/AvatarModal/AvatarModal.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Settings/components/AvatarModal/AvatarModal" */))
const _ffee302a = () => interopDefault(import('../pages/Account/UserSettings/components/Settings/components/EmailModal/EmailModal.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Settings/components/EmailModal/EmailModal" */))
const _583d7b31 = () => interopDefault(import('../pages/Account/UserSettings/components/Settings/components/PasswordModal/PasswordModal.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Settings/components/PasswordModal/PasswordModal" */))
const _4cec385a = () => interopDefault(import('../pages/Auth/PasswordReset.vue' /* webpackChunkName: "pages/Auth/PasswordReset" */))
const _1aa3507b = () => interopDefault(import('../pages/Account/Inbox/Conversation.vue' /* webpackChunkName: "pages/Account/Inbox/Conversation" */))
const _4383f76f = () => interopDefault(import('../pages/Checkout/Payment/Cancel.vue' /* webpackChunkName: "pages/Checkout/Payment/Cancel" */))
const _08c49765 = () => interopDefault(import('../pages/Checkout/Payment/Failure.vue' /* webpackChunkName: "pages/Checkout/Payment/Failure" */))
const _41afc15e = () => interopDefault(import('../pages/Checkout/Payment/Success.vue' /* webpackChunkName: "pages/Checkout/Payment/Success" */))
const _c8ff1cae = () => interopDefault(import('../pages/ListPage/RecoBrandForYou.vue' /* webpackChunkName: "pages/ListPage/RecoBrandForYou" */))
const _253fd0fe = () => interopDefault(import('../pages/AddProduct/index.vue' /* webpackChunkName: "pages/AddProduct/index" */))
const _117863a4 = () => interopDefault(import('../pages/Category/index.vue' /* webpackChunkName: "pages/Category/index" */))
const _71ad2a0a = () => interopDefault(import('../pages/Member/index.vue' /* webpackChunkName: "pages/Member/index" */))
const _62302545 = () => interopDefault(import('../pages/ProductCard/index.vue' /* webpackChunkName: "pages/ProductCard/index" */))
const _ce6fadac = () => interopDefault(import('../pages/ListPage/SearchOld.vue' /* webpackChunkName: "pages/ListPage/SearchOld" */))
const _4376572c = () => interopDefault(import('../pages/Bundle/Create.vue' /* webpackChunkName: "pages/Bundle/Create" */))
const _264129ba = () => interopDefault(import('../pages/Account/Verified/index.vue' /* webpackChunkName: "pages/Account/Verified/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bienvenue",
    component: _f61acc70,
    name: "Welcome___fr"
  }, {
    path: "/catalog",
    component: _2a2925bf,
    name: "Catalog___fr"
  }, {
    path: "/comment-ca-marche",
    component: _c9332952,
    name: "HowItWorks___fr"
  }, {
    path: "/conditions-generales",
    component: _4be25418,
    name: "TermsAndConditions___fr"
  }, {
    path: "/de",
    component: _2a433156,
    name: "index___de"
  }, {
    path: "/en",
    component: _2a433156,
    name: "index___en"
  }, {
    path: "/impressum",
    component: _5e1d71dd,
    name: "Impressum___fr"
  }, {
    path: "/logout",
    component: _6f078a94,
    name: "Auth-Logout___fr"
  }, {
    path: "/politique-de-confidentialite",
    component: _5ab63300,
    name: "PrivacyPolicy___fr"
  }, {
    path: "/search",
    component: _13db8a6d,
    name: "ListPage-Search___fr"
  }, {
    path: "/account/historique-de-recherche",
    component: _a8e3db20,
    name: "Account-SearchHistory___fr"
  }, {
    path: "/account/inbox",
    component: _0a6ec3aa,
    name: "Account-Inbox___fr"
  }, {
    path: "/account/user-settings",
    component: _4defb900,
    name: "Account-UserSettings___fr"
  }, {
    path: "/auth/reset-password",
    component: _33376cf6,
    name: "Auth-PasswordResetRequest___fr"
  }, {
    path: "/auth/signin",
    component: _231f04cc,
    name: "Auth-SignIn___fr"
  }, {
    path: "/auth/signup",
    component: _37b55b42,
    name: "Auth-SignUp___fr"
  }, {
    path: "/auth/social-redirect",
    component: _59d7dc1a,
    name: "Auth-SocialRedirect___fr"
  }, {
    path: "/checkout/cart",
    component: _1a64abcf,
    name: "Checkout-Cart___fr"
  }, {
    path: "/checkout/cart-old",
    component: _36fb93e4,
    name: "Checkout-Cart-old___fr"
  }, {
    path: "/de/allgemeine-geschaftsbedingungen",
    component: _4be25418,
    name: "TermsAndConditions___de"
  }, {
    path: "/de/catalog",
    component: _2a2925bf,
    name: "Catalog___de"
  }, {
    path: "/de/datenschutzrichtlinie-der-clozen-plattform",
    component: _5ab63300,
    name: "PrivacyPolicy___de"
  }, {
    path: "/de/impressum",
    component: _5e1d71dd,
    name: "Impressum___de"
  }, {
    path: "/de/logout",
    component: _6f078a94,
    name: "Auth-Logout___de"
  }, {
    path: "/de/search",
    component: _13db8a6d,
    name: "ListPage-Search___de"
  }, {
    path: "/de/wie-es-funktioniert",
    component: _c9332952,
    name: "HowItWorks___de"
  }, {
    path: "/de/willkommen",
    component: _f61acc70,
    name: "Welcome___de"
  }, {
    path: "/en/catalog",
    component: _2a2925bf,
    name: "Catalog___en"
  }, {
    path: "/en/how-it-works",
    component: _c9332952,
    name: "HowItWorks___en"
  }, {
    path: "/en/imprint",
    component: _5e1d71dd,
    name: "Impressum___en"
  }, {
    path: "/en/logout",
    component: _6f078a94,
    name: "Auth-Logout___en"
  }, {
    path: "/en/privacy-policy",
    component: _5ab63300,
    name: "PrivacyPolicy___en"
  }, {
    path: "/en/search",
    component: _13db8a6d,
    name: "ListPage-Search___en"
  }, {
    path: "/en/terms-and-conditions",
    component: _4be25418,
    name: "TermsAndConditions___en"
  }, {
    path: "/en/welcome",
    component: _f61acc70,
    name: "Welcome___en"
  }, {
    path: "/list/articles-populaires",
    component: _d8bc750a,
    name: "ListPage-RecoPopularForYou___fr"
  }, {
    path: "/list/articles-recemment-consultes",
    component: _805f1d42,
    name: "ListPage-RecentlyViewedItems___fr"
  }, {
    path: "/list/dernieres-publications",
    component: _b0d7c49c,
    name: "ListPage-LatestPublished___fr"
  }, {
    path: "/list/fil-d-actu",
    component: _67b14d15,
    name: "ListPage-RecoNewsFeed___fr"
  }, {
    path: "/list/highlighted",
    component: _c2304944,
    name: "ListPage-Highlighted___fr"
  }, {
    path: "/list/mes-favoris",
    component: _70fcbc97,
    name: "ListPage-UserFavorites___fr"
  }, {
    path: "/list/recommande-pour-toi",
    component: _a43d0dcc,
    name: "ListPage-RecoForYou___fr"
  }, {
    path: "/de/account/inbox",
    component: _0a6ec3aa,
    name: "Account-Inbox___de"
  }, {
    path: "/de/account/suchverlauf",
    component: _a8e3db20,
    name: "Account-SearchHistory___de"
  }, {
    path: "/de/account/user-settings",
    component: _4defb900,
    name: "Account-UserSettings___de"
  }, {
    path: "/de/auth/reset-password",
    component: _33376cf6,
    name: "Auth-PasswordResetRequest___de"
  }, {
    path: "/de/auth/signin",
    component: _231f04cc,
    name: "Auth-SignIn___de"
  }, {
    path: "/de/auth/signup",
    component: _37b55b42,
    name: "Auth-SignUp___de"
  }, {
    path: "/de/auth/social-redirect",
    component: _59d7dc1a,
    name: "Auth-SocialRedirect___de"
  }, {
    path: "/de/checkout/cart",
    component: _1a64abcf,
    name: "Checkout-Cart___de"
  }, {
    path: "/de/checkout/cart-old",
    component: _36fb93e4,
    name: "Checkout-Cart-old___de"
  }, {
    path: "/de/list/beliebte-artikel",
    component: _d8bc750a,
    name: "ListPage-RecoPopularForYou___de"
  }, {
    path: "/de/list/empfohlen-fur-dich",
    component: _a43d0dcc,
    name: "ListPage-RecoForYou___de"
  }, {
    path: "/de/list/highlighted",
    component: _c2304944,
    name: "ListPage-Highlighted___de"
  }, {
    path: "/de/list/letzte-veroffentlichungen",
    component: _b0d7c49c,
    name: "ListPage-LatestPublished___de"
  }, {
    path: "/de/list/meine-lieblingsartikel",
    component: _70fcbc97,
    name: "ListPage-UserFavorites___de"
  }, {
    path: "/de/list/news-feed",
    component: _67b14d15,
    name: "ListPage-RecoNewsFeed___de"
  }, {
    path: "/de/list/zuletzt-angesehenen-artikel",
    component: _805f1d42,
    name: "ListPage-RecentlyViewedItems___de"
  }, {
    path: "/en/account/inbox",
    component: _0a6ec3aa,
    name: "Account-Inbox___en"
  }, {
    path: "/en/account/search-history",
    component: _a8e3db20,
    name: "Account-SearchHistory___en"
  }, {
    path: "/en/account/user-settings",
    component: _4defb900,
    name: "Account-UserSettings___en"
  }, {
    path: "/en/auth/reset-password",
    component: _33376cf6,
    name: "Auth-PasswordResetRequest___en"
  }, {
    path: "/en/auth/signin",
    component: _231f04cc,
    name: "Auth-SignIn___en"
  }, {
    path: "/en/auth/signup",
    component: _37b55b42,
    name: "Auth-SignUp___en"
  }, {
    path: "/en/auth/social-redirect",
    component: _59d7dc1a,
    name: "Auth-SocialRedirect___en"
  }, {
    path: "/en/checkout/cart",
    component: _1a64abcf,
    name: "Checkout-Cart___en"
  }, {
    path: "/en/checkout/cart-old",
    component: _36fb93e4,
    name: "Checkout-Cart-old___en"
  }, {
    path: "/en/list/highlighted",
    component: _c2304944,
    name: "ListPage-Highlighted___en"
  }, {
    path: "/en/list/latest-publications",
    component: _b0d7c49c,
    name: "ListPage-LatestPublished___en"
  }, {
    path: "/en/list/my-favorites",
    component: _70fcbc97,
    name: "ListPage-UserFavorites___en"
  }, {
    path: "/en/list/news-feed",
    component: _67b14d15,
    name: "ListPage-RecoNewsFeed___en"
  }, {
    path: "/en/list/popular-articles",
    component: _d8bc750a,
    name: "ListPage-RecoPopularForYou___en"
  }, {
    path: "/en/list/recently-viewed-items",
    component: _805f1d42,
    name: "ListPage-RecentlyViewedItems___en"
  }, {
    path: "/en/list/recommended-for-you",
    component: _a43d0dcc,
    name: "ListPage-RecoForYou___en"
  }, {
    path: "/Member/components/Dressing/Dressing",
    component: _1b8652ce,
    name: "Member-components-Dressing-Dressing___fr"
  }, {
    path: "/Member/components/Ratings/Ratings",
    component: _605c68c8,
    name: "Member-components-Ratings-Ratings___fr"
  }, {
    path: "/Member/components/UserReport/UserReport",
    component: _3c8ec9ce,
    name: "Member-components-UserReport-UserReport___fr"
  }, {
    path: "/Account/UserSettings/components/Bank/Bank",
    component: _bc14fe90,
    name: "Account-UserSettings-components-Bank-Bank___fr"
  }, {
    path: "/Account/UserSettings/components/BrandList/Brand",
    component: _233a6c46,
    name: "Account-UserSettings-components-BrandList-Brand___fr"
  }, {
    path: "/Account/UserSettings/components/BrandList/BrandList",
    component: _66317b04,
    name: "Account-UserSettings-components-BrandList-BrandList___fr"
  }, {
    path: "/Account/UserSettings/components/BundleDiscount/BundleDiscount",
    component: _0939df98,
    name: "Account-UserSettings-components-BundleDiscount-BundleDiscount___fr"
  }, {
    path: "/Account/UserSettings/components/Customization/Color",
    component: _fd7ca9a0,
    name: "Account-UserSettings-components-Customization-Color___fr"
  }, {
    path: "/Account/UserSettings/components/Customization/Customization",
    component: _3e2032c0,
    name: "Account-UserSettings-components-Customization-Customization___fr"
  }, {
    path: "/Account/UserSettings/components/Holidays/Holidays",
    component: _4f00b918,
    name: "Account-UserSettings-components-Holidays-Holidays___fr"
  }, {
    path: "/Account/UserSettings/components/Menu/Menu",
    component: _53e2e2d0,
    name: "Account-UserSettings-components-Menu-Menu___fr"
  }, {
    path: "/Account/UserSettings/components/MyAddresses/MyAddresses",
    component: _2c5a1fa6,
    name: "Account-UserSettings-components-MyAddresses-MyAddresses___fr"
  }, {
    path: "/Account/UserSettings/components/MyPurchases/MyPurchases",
    component: _70390786,
    name: "Account-UserSettings-components-MyPurchases-MyPurchases___fr"
  }, {
    path: "/Account/UserSettings/components/MySales/MySales",
    component: _90be320c,
    name: "Account-UserSettings-components-MySales-MySales___fr"
  }, {
    path: "/Account/UserSettings/components/Notification/Notification",
    component: _0ed13fd0,
    name: "Account-UserSettings-components-Notification-Notification___fr"
  }, {
    path: "/Account/UserSettings/components/Settings/Settings",
    component: _ff183bd0,
    name: "Account-UserSettings-components-Settings-Settings___fr"
  }, {
    path: "/Account/UserSettings/components/TaxonCustomization/TaxonCustomization",
    component: _0340d250,
    name: "Account-UserSettings-components-TaxonCustomization-TaxonCustomization___fr"
  }, {
    path: "/Account/UserSettings/components/Wallet/Wallet",
    component: _7cf8e8d8,
    name: "Account-UserSettings-components-Wallet-Wallet___fr"
  }, {
    path: "/Checkout/Cart/components/PaymentSelector/PaymentSelector",
    component: _0ad2d215,
    name: "Checkout-Cart-components-PaymentSelector-PaymentSelector___fr"
  }, {
    path: "/de/Member/components/Dressing/Dressing",
    component: _1b8652ce,
    name: "Member-components-Dressing-Dressing___de"
  }, {
    path: "/de/Member/components/Ratings/Ratings",
    component: _605c68c8,
    name: "Member-components-Ratings-Ratings___de"
  }, {
    path: "/de/Member/components/UserReport/UserReport",
    component: _3c8ec9ce,
    name: "Member-components-UserReport-UserReport___de"
  }, {
    path: "/en/Member/components/Dressing/Dressing",
    component: _1b8652ce,
    name: "Member-components-Dressing-Dressing___en"
  }, {
    path: "/en/Member/components/Ratings/Ratings",
    component: _605c68c8,
    name: "Member-components-Ratings-Ratings___en"
  }, {
    path: "/en/Member/components/UserReport/UserReport",
    component: _3c8ec9ce,
    name: "Member-components-UserReport-UserReport___en"
  }, {
    path: "/Account/UserSettings/components/MyAddresses/components/PostalAddress",
    component: _c08323e0,
    name: "Account-UserSettings-components-MyAddresses-components-PostalAddress___fr"
  }, {
    path: "/de/Account/UserSettings/components/Bank/Bank",
    component: _bc14fe90,
    name: "Account-UserSettings-components-Bank-Bank___de"
  }, {
    path: "/de/Account/UserSettings/components/BrandList/Brand",
    component: _233a6c46,
    name: "Account-UserSettings-components-BrandList-Brand___de"
  }, {
    path: "/de/Account/UserSettings/components/BrandList/BrandList",
    component: _66317b04,
    name: "Account-UserSettings-components-BrandList-BrandList___de"
  }, {
    path: "/de/Account/UserSettings/components/BundleDiscount/BundleDiscount",
    component: _0939df98,
    name: "Account-UserSettings-components-BundleDiscount-BundleDiscount___de"
  }, {
    path: "/de/Account/UserSettings/components/Customization/Color",
    component: _fd7ca9a0,
    name: "Account-UserSettings-components-Customization-Color___de"
  }, {
    path: "/de/Account/UserSettings/components/Customization/Customization",
    component: _3e2032c0,
    name: "Account-UserSettings-components-Customization-Customization___de"
  }, {
    path: "/de/Account/UserSettings/components/Holidays/Holidays",
    component: _4f00b918,
    name: "Account-UserSettings-components-Holidays-Holidays___de"
  }, {
    path: "/de/Account/UserSettings/components/Menu/Menu",
    component: _53e2e2d0,
    name: "Account-UserSettings-components-Menu-Menu___de"
  }, {
    path: "/de/Account/UserSettings/components/MyAddresses/MyAddresses",
    component: _2c5a1fa6,
    name: "Account-UserSettings-components-MyAddresses-MyAddresses___de"
  }, {
    path: "/de/Account/UserSettings/components/MyPurchases/MyPurchases",
    component: _70390786,
    name: "Account-UserSettings-components-MyPurchases-MyPurchases___de"
  }, {
    path: "/de/Account/UserSettings/components/MySales/MySales",
    component: _90be320c,
    name: "Account-UserSettings-components-MySales-MySales___de"
  }, {
    path: "/de/Account/UserSettings/components/Notification/Notification",
    component: _0ed13fd0,
    name: "Account-UserSettings-components-Notification-Notification___de"
  }, {
    path: "/de/Account/UserSettings/components/Settings/Settings",
    component: _ff183bd0,
    name: "Account-UserSettings-components-Settings-Settings___de"
  }, {
    path: "/de/Account/UserSettings/components/TaxonCustomization/TaxonCustomization",
    component: _0340d250,
    name: "Account-UserSettings-components-TaxonCustomization-TaxonCustomization___de"
  }, {
    path: "/de/Account/UserSettings/components/Wallet/Wallet",
    component: _7cf8e8d8,
    name: "Account-UserSettings-components-Wallet-Wallet___de"
  }, {
    path: "/de/Checkout/Cart/components/PaymentSelector/PaymentSelector",
    component: _0ad2d215,
    name: "Checkout-Cart-components-PaymentSelector-PaymentSelector___de"
  }, {
    path: "/en/Account/UserSettings/components/Bank/Bank",
    component: _bc14fe90,
    name: "Account-UserSettings-components-Bank-Bank___en"
  }, {
    path: "/en/Account/UserSettings/components/BrandList/Brand",
    component: _233a6c46,
    name: "Account-UserSettings-components-BrandList-Brand___en"
  }, {
    path: "/en/Account/UserSettings/components/BrandList/BrandList",
    component: _66317b04,
    name: "Account-UserSettings-components-BrandList-BrandList___en"
  }, {
    path: "/en/Account/UserSettings/components/BundleDiscount/BundleDiscount",
    component: _0939df98,
    name: "Account-UserSettings-components-BundleDiscount-BundleDiscount___en"
  }, {
    path: "/en/Account/UserSettings/components/Customization/Color",
    component: _fd7ca9a0,
    name: "Account-UserSettings-components-Customization-Color___en"
  }, {
    path: "/en/Account/UserSettings/components/Customization/Customization",
    component: _3e2032c0,
    name: "Account-UserSettings-components-Customization-Customization___en"
  }, {
    path: "/en/Account/UserSettings/components/Holidays/Holidays",
    component: _4f00b918,
    name: "Account-UserSettings-components-Holidays-Holidays___en"
  }, {
    path: "/en/Account/UserSettings/components/Menu/Menu",
    component: _53e2e2d0,
    name: "Account-UserSettings-components-Menu-Menu___en"
  }, {
    path: "/en/Account/UserSettings/components/MyAddresses/MyAddresses",
    component: _2c5a1fa6,
    name: "Account-UserSettings-components-MyAddresses-MyAddresses___en"
  }, {
    path: "/en/Account/UserSettings/components/MyPurchases/MyPurchases",
    component: _70390786,
    name: "Account-UserSettings-components-MyPurchases-MyPurchases___en"
  }, {
    path: "/en/Account/UserSettings/components/MySales/MySales",
    component: _90be320c,
    name: "Account-UserSettings-components-MySales-MySales___en"
  }, {
    path: "/en/Account/UserSettings/components/Notification/Notification",
    component: _0ed13fd0,
    name: "Account-UserSettings-components-Notification-Notification___en"
  }, {
    path: "/en/Account/UserSettings/components/Settings/Settings",
    component: _ff183bd0,
    name: "Account-UserSettings-components-Settings-Settings___en"
  }, {
    path: "/en/Account/UserSettings/components/TaxonCustomization/TaxonCustomization",
    component: _0340d250,
    name: "Account-UserSettings-components-TaxonCustomization-TaxonCustomization___en"
  }, {
    path: "/en/Account/UserSettings/components/Wallet/Wallet",
    component: _7cf8e8d8,
    name: "Account-UserSettings-components-Wallet-Wallet___en"
  }, {
    path: "/en/Checkout/Cart/components/PaymentSelector/PaymentSelector",
    component: _0ad2d215,
    name: "Checkout-Cart-components-PaymentSelector-PaymentSelector___en"
  }, {
    path: "/Account/UserSettings/components/Settings/components/AvatarModal/AvatarModal",
    component: _5f5e14f5,
    name: "Account-UserSettings-components-Settings-components-AvatarModal-AvatarModal___fr"
  }, {
    path: "/Account/UserSettings/components/Settings/components/EmailModal/EmailModal",
    component: _ffee302a,
    name: "Account-UserSettings-components-Settings-components-EmailModal-EmailModal___fr"
  }, {
    path: "/Account/UserSettings/components/Settings/components/PasswordModal/PasswordModal",
    component: _583d7b31,
    name: "Account-UserSettings-components-Settings-components-PasswordModal-PasswordModal___fr"
  }, {
    path: "/de/Account/UserSettings/components/MyAddresses/components/PostalAddress",
    component: _c08323e0,
    name: "Account-UserSettings-components-MyAddresses-components-PostalAddress___de"
  }, {
    path: "/en/Account/UserSettings/components/MyAddresses/components/PostalAddress",
    component: _c08323e0,
    name: "Account-UserSettings-components-MyAddresses-components-PostalAddress___en"
  }, {
    path: "/de/Account/UserSettings/components/Settings/components/AvatarModal/AvatarModal",
    component: _5f5e14f5,
    name: "Account-UserSettings-components-Settings-components-AvatarModal-AvatarModal___de"
  }, {
    path: "/de/Account/UserSettings/components/Settings/components/EmailModal/EmailModal",
    component: _ffee302a,
    name: "Account-UserSettings-components-Settings-components-EmailModal-EmailModal___de"
  }, {
    path: "/de/Account/UserSettings/components/Settings/components/PasswordModal/PasswordModal",
    component: _583d7b31,
    name: "Account-UserSettings-components-Settings-components-PasswordModal-PasswordModal___de"
  }, {
    path: "/en/Account/UserSettings/components/Settings/components/AvatarModal/AvatarModal",
    component: _5f5e14f5,
    name: "Account-UserSettings-components-Settings-components-AvatarModal-AvatarModal___en"
  }, {
    path: "/en/Account/UserSettings/components/Settings/components/EmailModal/EmailModal",
    component: _ffee302a,
    name: "Account-UserSettings-components-Settings-components-EmailModal-EmailModal___en"
  }, {
    path: "/en/Account/UserSettings/components/Settings/components/PasswordModal/PasswordModal",
    component: _583d7b31,
    name: "Account-UserSettings-components-Settings-components-PasswordModal-PasswordModal___en"
  }, {
    path: "/de/auth/reset-password/reset/:token",
    component: _4cec385a,
    name: "Auth-PasswordReset___de"
  }, {
    path: "/en/auth/reset-password/reset/:token",
    component: _4cec385a,
    name: "Auth-PasswordReset___en"
  }, {
    path: "/auth/reset-password/reset/:token",
    component: _4cec385a,
    name: "Auth-PasswordReset___fr"
  }, {
    path: "/de/account/inbox/:conversationId",
    component: _1aa3507b,
    name: "Account-Inbox-Conversation___de"
  }, {
    path: "/en/account/inbox/:conversationId",
    component: _1aa3507b,
    name: "Account-Inbox-Conversation___en"
  }, {
    path: "/de/checkout/payment/:transactionId/cancel",
    component: _4383f76f,
    name: "Checkout-Payment-Cancel___de"
  }, {
    path: "/de/checkout/payment/:transactionId/failure",
    component: _08c49765,
    name: "Checkout-Payment-Failure___de"
  }, {
    path: "/de/checkout/payment/:transactionId/success",
    component: _41afc15e,
    name: "Checkout-Payment-Success___de"
  }, {
    path: "/de/list/marke/:slug/fur-dich",
    component: _c8ff1cae,
    name: "ListPage-RecoBrandForYou___de"
  }, {
    path: "/en/checkout/payment/:transactionId/cancel",
    component: _4383f76f,
    name: "Checkout-Payment-Cancel___en"
  }, {
    path: "/en/checkout/payment/:transactionId/failure",
    component: _08c49765,
    name: "Checkout-Payment-Failure___en"
  }, {
    path: "/en/checkout/payment/:transactionId/success",
    component: _41afc15e,
    name: "Checkout-Payment-Success___en"
  }, {
    path: "/en/list/brand/:slug/for-you",
    component: _c8ff1cae,
    name: "ListPage-RecoBrandForYou___en"
  }, {
    path: "/account/inbox/:conversationId",
    component: _1aa3507b,
    name: "Account-Inbox-Conversation___fr"
  }, {
    path: "/de/artikel-hinzufugen/:id?",
    component: _253fd0fe,
    name: "AddProduct___de"
  }, {
    path: "/de/category/:slug",
    component: _117863a4,
    name: "Category___de"
  }, {
    path: "/de/member/:slug",
    component: _71ad2a0a,
    name: "Member___de"
  }, {
    path: "/de/product/:slug",
    component: _62302545,
    name: "ProductCard___de"
  }, {
    path: "/de/search/:query",
    component: _ce6fadac,
    name: "ListPage-SearchOld___de"
  }, {
    path: "/en/add-item/:id?",
    component: _253fd0fe,
    name: "AddProduct___en"
  }, {
    path: "/en/category/:slug",
    component: _117863a4,
    name: "Category___en"
  }, {
    path: "/en/member/:slug",
    component: _71ad2a0a,
    name: "Member___en"
  }, {
    path: "/en/product/:slug",
    component: _62302545,
    name: "ProductCard___en"
  }, {
    path: "/en/search/:query",
    component: _ce6fadac,
    name: "ListPage-SearchOld___en"
  }, {
    path: "/checkout/payment/:transactionId/cancel",
    component: _4383f76f,
    name: "Checkout-Payment-Cancel___fr"
  }, {
    path: "/checkout/payment/:transactionId/failure",
    component: _08c49765,
    name: "Checkout-Payment-Failure___fr"
  }, {
    path: "/checkout/payment/:transactionId/success",
    component: _41afc15e,
    name: "Checkout-Payment-Success___fr"
  }, {
    path: "/de/user/:user/create-bundle",
    component: _4376572c,
    name: "Bundle-Create___de"
  }, {
    path: "/en/user/:user/create-bundle",
    component: _4376572c,
    name: "Bundle-Create___en"
  }, {
    path: "/list/marque/:slug/pour-toi",
    component: _c8ff1cae,
    name: "ListPage-RecoBrandForYou___fr"
  }, {
    path: "/ajout-article/:id?",
    component: _253fd0fe,
    name: "AddProduct___fr"
  }, {
    path: "/category/:slug",
    component: _117863a4,
    name: "Category___fr"
  }, {
    path: "/member/:slug",
    component: _71ad2a0a,
    name: "Member___fr"
  }, {
    path: "/product/:slug",
    component: _62302545,
    name: "ProductCard___fr"
  }, {
    path: "/search/:query",
    component: _ce6fadac,
    name: "ListPage-SearchOld___fr"
  }, {
    path: "/de/:slug/willkommen",
    component: _264129ba,
    name: "Account-Verified___de"
  }, {
    path: "/en/:slug/welcome",
    component: _264129ba,
    name: "Account-Verified___en"
  }, {
    path: "/user/:user/create-bundle",
    component: _4376572c,
    name: "Bundle-Create___fr"
  }, {
    path: "/",
    component: _2a433156,
    name: "index___fr"
  }, {
    path: "/:slug/bienvenue",
    component: _264129ba,
    name: "Account-Verified___fr"
  }],

  parseQuery: function(query) {
      return require('qs').parse(query);
    },
  stringifyQuery: function(query) {
      const res = require('qs').stringify(query);
      return res ? '?' + res : '';
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
