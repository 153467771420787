var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notification-dropdown"},[_c('div',{staticClass:"notification-dropdown__entries"},_vm._l((_vm.notifications),function(notification){return _c('div',{key:notification.id,staticClass:"notification-dropdown__entry"},[_c('NuxtLink',{staticClass:"notification-dropdown__entry__link",attrs:{"to":_vm.redirectTo(notification),"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{attrs:{"href":href,"role":"link"},on:{"click":function($event){return _vm.onNavigate(navigate, notification, $event)}}},[_c('div',{staticClass:"notification-dropdown__entry__heading"},[(_vm.notifiedAtFormatted(notification))?_c('span',{staticClass:"notification-dropdown__entry__heading__date"},[_vm._v("\n              "+_vm._s(_vm.notifiedAtFormatted(notification))+"\n            ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"notification-dropdown__entry__content"},[(notification.type === 'new-review')?[_c('div',{staticClass:"notification-dropdown__entry__content__with-preview"},[(notification.payload.from.avatar)?_c('img',{staticClass:"notification-dropdown__entry__content__preview",attrs:{"src":notification.payload.from.avatar,"alt":""}}):_c('img',{staticClass:"notification-dropdown__entry__content__preview",attrs:{"src":require("assets/img/user-sm.png"),"alt":""}}),_vm._v(" "),_c('div',[_vm._v("\n                  "+_vm._s(_vm.$t('notifications.new-review.content', {
                      username: notification.payload.from.username
                    }))+"\n                ")])])]:_vm._e(),_vm._v(" "),(notification.type === 'review-response')?[_c('div',{staticClass:"notification-dropdown__entry__content__with-preview"},[(notification.payload.from.avatar)?_c('img',{staticClass:"notification-dropdown__entry__content__preview",attrs:{"src":notification.payload.from.avatar,"alt":""}}):_c('img',{staticClass:"notification-dropdown__entry__content__preview",attrs:{"src":require("assets/img/user-sm.png"),"alt":""}}),_vm._v(" "),_c('div',[_vm._v("\n                  "+_vm._s(_vm.$t('notifications.review-response.content', {
                      username: notification.payload.from.username
                    }))+"\n                ")])])]:_vm._e(),_vm._v(" "),(notification.type === 'item-liked')?[_c('div',{staticClass:"notification-dropdown__entry__content__with-preview"},[(notification.payload.item.media)?_c('img',{staticClass:"notification-dropdown__entry__content__preview",attrs:{"src":notification.payload.item.media,"alt":""}}):_vm._e(),_vm._v(" "),_c('div',[_vm._v("\n                  "+_vm._s(_vm.$t('notifications.item-liked.content', {
                      username: notification.payload.likedBy.username
                    }))+"\n                ")])])]:_vm._e(),_vm._v(" "),(notification.type === 'item-liked-another')?[_c('div',{staticClass:"notification-dropdown__entry__content__with-preview"},[(notification.payload.item.media)?_c('img',{staticClass:"notification-dropdown__entry__content__preview",attrs:{"src":notification.payload.item.media,"alt":""}}):_vm._e(),_vm._v(" "),_c('div',[_vm._v("\n                  "+_vm._s(_vm.$t('notifications.item-liked-another.content'))+"\n                ")])])]:_vm._e(),_vm._v(" "),(notification.type === 'item-liked-sold')?[_c('div',{staticClass:"notification-dropdown__entry__content__with-preview"},[(notification.payload.item.media)?_c('img',{staticClass:"notification-dropdown__entry__content__preview",attrs:{"src":notification.payload.item.media,"alt":""}}):_vm._e(),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.$t('notifications.item-liked-sold.content')))])])]:_vm._e(),_vm._v(" "),(notification.type === 'lower-price')?[_c('div',{staticClass:"notification-dropdown__entry__content__with-preview"},[(notification.payload.item.media)?_c('img',{staticClass:"notification-dropdown__entry__content__preview",attrs:{"src":notification.payload.item.media,"alt":""}}):_vm._e(),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(
                    _vm.$t('notifications.lower-price.content', {
                      title: notification.payload.item.title,
                      oldCurrency: _vm.$options.filters.toCurrency(
                        notification.payload.initialPrice
                      ),
                      currency: _vm.$options.filters.toCurrency(
                        notification.payload.price
                      )
                    })
                  )}})])]:_vm._e(),_vm._v(" "),(notification.type === 'new-followee-item')?[_c('div',{staticClass:"notification-dropdown__entry__content__with-preview"},[(notification.payload.item.media)?_c('img',{staticClass:"notification-dropdown__entry__content__preview",attrs:{"src":notification.payload.item.media,"alt":""}}):_vm._e(),_vm._v(" "),_c('div',[_vm._v("\n                  "+_vm._s(_vm.$t('notifications.new-followee-item.content', {
                      username: notification.payload.seller.username
                    }))+"\n                ")])])]:_vm._e(),_vm._v(" "),(notification.type === 'new-follower')?[_c('div',{staticClass:"notification-dropdown__entry__content__with-preview"},[(notification.payload.follower.avatar)?_c('img',{staticClass:"notification-dropdown__entry__content__preview",attrs:{"src":notification.payload.follower.avatar,"alt":""}}):_c('img',{staticClass:"notification-dropdown__entry__content__preview",attrs:{"src":require("assets/img/user-sm.png"),"alt":""}}),_vm._v(" "),_c('div',[_vm._v("\n                  "+_vm._s(_vm.$t('notifications.new-follower.content', {
                      username: notification.payload.follower.username
                    }))+"\n                ")])])]:_vm._e()],2)])]}}],null,true)})],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }