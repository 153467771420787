import VScrollLock from 'v-scroll-lock';
import Vue from 'vue';

Vue.use(VScrollLock, {
  bodyScrollOptions: {
    allowTouchMove: (el) => {
      while (el && el !== document.body) {
        if (el.getAttribute('body-scroll-lock-ignore') !== null) {
          return true;
        }

        el = el.parentElement;
      }
    }
  }
});
