<template>
  <div v-if="searches && searches.length" class="saved-search">
    <div class="saved-search__heading">
      {{ $t('components.save-search.widget.heading') }}
    </div>
    <div class="saved-search__searches">
      <div
        v-for="search in searches"
        :key="search.id"
        class="saved-search__searches__search"
        :class="{
          'saved-search__searches__search--deleting': search === deleting
        }"
        @click="onClick(search)"
      >
        <div class="saved-search__searches__search__payload">
          {{ humanize(search) }}
        </div>
        <div
          class="saved-search__searches__search__bookmark"
          @click="onRemove($event, search)"
        >
          <RemoveBookmarkSvg />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RemoveBookmarkSvg from '~/assets/img/remove-bookmark.svg?inline';
import { mapActions, mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
import { removeSearch } from '@/api/user';
import { sleep } from '@/misc/utils';
import isEmpty from 'lodash.isempty';

export default {
  name: 'SavedSearch',
  components: { RemoveBookmarkSvg },
  data: () => ({ deleting: null }),
  async mounted() {
    await this.fetchSearches();
  },
  computed: {
    ...mapState(useUserStore, ['searches'])
  },
  methods: {
    ...mapActions(useUserStore, ['fetchSearches']),
    humanize(search) {
      let str = '';
      const appendToStr = (str, strToAppend) =>
        `${str}${!isEmpty(str) ? ', ' : ''}${strToAppend}`;
      if (search.payload.query) {
        str = appendToStr(str, search.payload.query);
      }
      if (search.payload.taxon) {
        str = appendToStr(str, search.payload.taxon.join(', '));
      }
      Object.keys(search.payload)
        .filter((k) => !['query', 'taxon'].includes(k))
        .forEach((key) => {
          const data = search.payload[key];
          str = appendToStr(
            str,
            (Array.isArray(data) && data.join(', ')) || data
          );
        });
      return str;
    },
    async onClick(search) {
      this.$emit('search:click', search);
    },
    async onRemove(event, search) {
      event.stopPropagation();
      try {
        this.deleting = search;
        await this.$api.doRequest(removeSearch, search.id);
        await sleep(100);
        await this.fetchSearches();
        this.deleting = null;
      } catch {}
    }
  }
};
</script>

<style lang="scss" scoped src="./saved-search.scss"></style>
