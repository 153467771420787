import https from 'https';
import { isNil } from 'lodash';

export default function (ctx) {
  const { $axios, i18n } = ctx;
  $axios.defaults.httpsAgent = new https.Agent({
    rejectUnauthorized: process.env.NODE_ENV !== 'development'
  });

  $axios.onRequest((config) => {
    if (process.client && window.localStorage) {
      const switchedUser = localStorage.getItem('_switch_user');
      if (!isNil(switchedUser)) {
        $axios.setHeader('X-Switch-User', switchedUser);
      }
    }
    if (!config.header?.['Accept-Language']) {
      const locale = ctx.$auth?.user?.preferences?.locale || i18n.locale;
      $axios.setHeader('Accept-Language', locale);
    }
  });

  $axios.onResponse((response) => {
    // if (process.server) {
    //   console.log(ctx.req.url);
    //   console.log(response);
    // }
  });
}
