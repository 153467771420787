<template>
  <div class="notification-dropdown">
    <div class="notification-dropdown__entries">
      <div
        v-for="notification in notifications"
        :key="notification.id"
        class="notification-dropdown__entry"
      >
        <NuxtLink
          v-slot="{ href, navigate }"
          :to="redirectTo(notification)"
          custom
          class="notification-dropdown__entry__link"
        >
          <a
            :href="href"
            role="link"
            @click="onNavigate(navigate, notification, $event)"
          >
            <div class="notification-dropdown__entry__heading">
              <!-- <span class="notification-dropdown__entry__heading__title">
              {{ $t(`notifications.${notification.type}.heading`) }}
            </span> -->
              <span
                v-if="notifiedAtFormatted(notification)"
                class="notification-dropdown__entry__heading__date"
              >
                {{ notifiedAtFormatted(notification) }}
              </span>
            </div>
            <div class="notification-dropdown__entry__content">
              <template v-if="notification.type === 'new-review'">
                <div
                  class="notification-dropdown__entry__content__with-preview"
                >
                  <img
                    v-if="notification.payload.from.avatar"
                    :src="notification.payload.from.avatar"
                    alt=""
                    class="notification-dropdown__entry__content__preview"
                  />
                  <img
                    v-else
                    src="~/assets/img/user-sm.png"
                    alt=""
                    class="notification-dropdown__entry__content__preview"
                  />
                  <div>
                    {{
                      $t('notifications.new-review.content', {
                        username: notification.payload.from.username
                      })
                    }}
                  </div>
                </div>
                <!-- <div>Note {{ notification.payload.rating }}</div>
            <div>Commentaire {{ notification.payload.review }}</div> -->
              </template>
              <template v-if="notification.type === 'review-response'">
                <div
                  class="notification-dropdown__entry__content__with-preview"
                >
                  <img
                    v-if="notification.payload.from.avatar"
                    :src="notification.payload.from.avatar"
                    alt=""
                    class="notification-dropdown__entry__content__preview"
                  />
                  <img
                    v-else
                    src="~/assets/img/user-sm.png"
                    alt=""
                    class="notification-dropdown__entry__content__preview"
                  />
                  <div>
                    {{
                      $t('notifications.review-response.content', {
                        username: notification.payload.from.username
                      })
                    }}
                  </div>
                </div>
              </template>
              <template v-if="notification.type === 'item-liked'">
                <div
                  class="notification-dropdown__entry__content__with-preview"
                >
                  <img
                    v-if="notification.payload.item.media"
                    :src="notification.payload.item.media"
                    alt=""
                    class="notification-dropdown__entry__content__preview"
                  />
                  <div>
                    {{
                      $t('notifications.item-liked.content', {
                        username: notification.payload.likedBy.username
                      })
                    }}
                  </div>
                </div>
              </template>
              <template v-if="notification.type === 'item-liked-another'">
                <div
                  class="notification-dropdown__entry__content__with-preview"
                >
                  <img
                    v-if="notification.payload.item.media"
                    :src="notification.payload.item.media"
                    alt=""
                    class="notification-dropdown__entry__content__preview"
                  />
                  <div>
                    {{ $t('notifications.item-liked-another.content') }}
                  </div>
                </div>
              </template>
              <template v-if="notification.type === 'item-liked-sold'">
                <div
                  class="notification-dropdown__entry__content__with-preview"
                >
                  <img
                    v-if="notification.payload.item.media"
                    :src="notification.payload.item.media"
                    alt=""
                    class="notification-dropdown__entry__content__preview"
                  />
                  <div>{{ $t('notifications.item-liked-sold.content') }}</div>
                </div>
              </template>
              <template v-if="notification.type === 'lower-price'">
                <div
                  class="notification-dropdown__entry__content__with-preview"
                >
                  <img
                    v-if="notification.payload.item.media"
                    :src="notification.payload.item.media"
                    alt=""
                    class="notification-dropdown__entry__content__preview"
                  />
                  <div
                    v-html="
                      $t('notifications.lower-price.content', {
                        title: notification.payload.item.title,
                        oldCurrency: $options.filters.toCurrency(
                          notification.payload.initialPrice
                        ),
                        currency: $options.filters.toCurrency(
                          notification.payload.price
                        )
                      })
                    "
                  />
                </div>
              </template>
              <template v-if="notification.type === 'new-followee-item'">
                <div
                  class="notification-dropdown__entry__content__with-preview"
                >
                  <img
                    v-if="notification.payload.item.media"
                    :src="notification.payload.item.media"
                    alt=""
                    class="notification-dropdown__entry__content__preview"
                  />
                  <div>
                    {{
                      $t('notifications.new-followee-item.content', {
                        username: notification.payload.seller.username
                      })
                    }}
                  </div>
                </div>
              </template>
              <template v-if="notification.type === 'new-follower'">
                <div
                  class="notification-dropdown__entry__content__with-preview"
                >
                  <img
                    v-if="notification.payload.follower.avatar"
                    :src="notification.payload.follower.avatar"
                    alt=""
                    class="notification-dropdown__entry__content__preview"
                  />
                  <img
                    v-else
                    src="~/assets/img/user-sm.png"
                    alt=""
                    class="notification-dropdown__entry__content__preview"
                  />
                  <div>
                    {{
                      $t('notifications.new-follower.content', {
                        username: notification.payload.follower.username
                      })
                    }}
                  </div>
                </div>
              </template>
            </div>
          </a>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script>
import { useInboxStore } from '@/stores/inbox';
import { postConversation } from '@/api/order';
import isNil from 'lodash.isnil';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import 'dayjs/locale/de';
import { mapState } from 'pinia';
import { extractUuid } from '@/misc/string';
import { captureErrorMixin } from '@/mixins/captureError';

dayjs.extend(relativeTime);

export default {
  name: 'NotificationDropdown',
  props: {
    notifications: {
      type: Array,
      default: () => []
    }
  },
  mixins: [captureErrorMixin],
  created() {
    dayjs.locale(this.$i18n.locale || 'fr');
  },
  computed: {
    ...mapState(useInboxStore, ['conversations'])
  },
  methods: {
    notifiedAtFormatted(notification) {
      if (isNil(notification.notifiedAt)) return null;
      return dayjs(notification.notifiedAt).fromNow();
    },
    getConversation(notification) {
      const {
        payload: { item, likedBy }
      } = notification;
      if (isNil(item) || isNil(likedBy)) return null;
      return this.conversations?.find(
        (c) =>
          c.buyer?.id === likedBy.id && extractUuid(c.item?.['@id']) === item.id
      );
    },
    redirectTo(notification) {
      if (notification.type === 'new-review') {
        return this.localePath({
          name: 'Member',
          params: { slug: this.$auth.user.slug },
          query: { tab: 'reviews' }
        });
      } else if (notification.type === 'review-response') {
        return this.localePath({
          name: 'Member',
          params: { slug: notification.payload.from.slug },
          query: { tab: 'reviews' }
        });
      } else if (notification.type === 'item-liked') {
        const conversation = this.getConversation(notification);
        if (isNil(conversation)) return '';
        return this.localePath({
          name: 'Account-Inbox-Conversation',
          params: { conversationId: conversation.id }
        });
      } else if (notification.type === 'item-liked-another') {
        return this.localePath({
          name: 'ProductCard',
          params: { slug: notification.payload.item.slug }
        });
      } else if (notification.type === 'item-liked-sold') {
        return this.localePath({
          name: 'ProductCard',
          params: { slug: notification.payload.item.slug }
        });
      } else if (notification.type === 'lower-price') {
        return this.localePath({
          name: 'ProductCard',
          params: { slug: notification.payload.item.slug }
        });
      } else if (notification.type === 'new-followee-item') {
        return this.localePath({
          name: 'ProductCard',
          params: { slug: notification.payload.item.slug }
        });
      } else if (notification.type === 'new-follower') {
        return this.localePath({
          name: 'Member',
          params: { slug: notification.payload.follower.slug }
        });
      } else {
        return '';
      }
    },
    async onNavigate(navigate, notification, event) {
      this.$emit('notification:redirect');
      if (notification.type === 'item-liked') {
        event.preventDefault();
        let conversation = this.getConversation(notification);
        if (isNil(conversation)) {
          const {
            payload: { item, likedBy }
          } = notification;
          conversation = await this.try(
            async () =>
              await this.$api.doRequest(postConversation, {
                item: `/items/${item.id}`,
                buyer: `/users/${likedBy.id}`
              })
          );
        }
        const path = conversation?.id
          ? this.localePath({
              name: 'Account-Inbox-Conversation',
              params: { conversationId: conversation.id }
            })
          : this.localePath({ name: 'Account-Inbox' });
        return this.$router.push(path);
      }
      navigate(event);
    }
  }
};
</script>

<style lang="scss" scoped src="./notification-dropdown.scss"></style>
