import { getFeatureFlag } from '@/api/feature-flag';
import { store, fetch } from '@/misc/local-cache';
import isNil from 'lodash.isnil';

const CACHE_KEY_TMPL = 'feature:{name}';
const DEFAULT_TTL = 5 * 60 * 1000; // 5 min

export default ({ $api }, inject) => {
  inject('feature', {
    async isActive(name, useCache = true, ttl = null) {
      try {
        const cacheKey = CACHE_KEY_TMPL.replace('{name}', name);
        let isActive = useCache && process.client ? fetch(cacheKey) : null;
        if (isNil(isActive)) {
          const { active = false } = await $api.doRequest(getFeatureFlag, name);
          useCache &&
            process.client &&
            store(cacheKey, active, ttl ?? DEFAULT_TTL);
          isActive = active;
        }
        return isActive;
      } catch (e) {
        return false;
      }
    }
  });
};
